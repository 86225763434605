import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { getSocket } from "../../actions/socket/socket";
import Util from "../../util/Util.js";
import WebSocketStarter from "../../websocket/websocket";

import Roles from "../../enums/Roles";
import NotificationSubject from "../../enums/NotificationSubject";

import NotificationModal from "../../components/sub/modals/NotificationModal.js";
import RgpdModal from "../rgpd/RgpdModal";
import MenuMAD from "./Menus/MenuMAD";
import SwitchComponent from "./SwitchComponent";

import { getUser } from "../../actions/user/user";
import { getRgpd } from "../../actions/rgpd/rgpd";
import { getCart } from "../../actions/cart/cart";
import { getOrders, getOrdersAdmin } from "../../actions/orders/orders";
import { getClients, getClientsAdmin } from "../../actions/clients/clients";
import { getOrdersSettings } from "../../actions/settings/ordersSettings/ordersSettings";
import { getOrdersSettingsAdmin } from "../../actions/settings/ordersSettings/admin/ordersSettings";
import { getProductsSettingsAdmin } from "../../actions/settings/productsSettings/admin/productsSettings";
import { getAuditRooms } from "../../actions/settings/auditRooms/admin/auditRooms";
import { messagesNotifsPatients } from "../../actions/coordination/coordination.js";
import {
  getPromoOperations,
  getPromoOperationsAdmin,
} from "../../actions/promoOperations/promoOperations";
import { getAuditDMs } from "../../actions/settings/auditDM/admin/auditDM";
import { getRequests, getAllRequests } from "../../actions/requests/requests";
import {
  getNotifs,
  deleteNotif,
} from "../../actions/notifications/notifications";
import {
  getMercurialsAdmin,
  getMercurial,
} from "../../actions/mercurials/mercurials";
import { getCheckpoints } from "../../actions/checkpoints/checkpoints";
import { getCollaboratorTypes } from "../../actions/configurables/collaboratorTypes";
import { getGeneralSettings } from "../../actions/settings/generalSettings/generalSettings";
import { getCollaborators } from "../../actions/collaborators/collaborators";
import { getAdmin } from "../../actions/admin/admin";
import { getPatientsAdmin } from "../../actions/patients/patients";
import { getPatients } from "../../actions/patients/patients";
import { getPrescriberTypes } from "../../actions/settings/prescriberType/admin/prescriberType";
import { getAuditSettings } from "../../actions/settings/auditSettings/auditSettings";
import { getAuditDeskSettings } from "../../actions/settings/auditSettings/auditDeskSettings";
import { getMessages } from "../../actions/coordination/coordination.js";
import { getAudits, getAuditsAdmin } from "../../actions/audits/audits";
import { getCompany } from "../../actions/company/company";
import { getMaintenances } from "../../actions/maintenances/maintenances";
import {
  getInterventions,
  getInterventionsAdmin,
} from "../../actions/interventions/Interventions";
import { Container } from "react-bootstrap";
import SectionContainer from "../sub/bootstrap/SectionContainer";

class Homepage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rgpdModal: null,
    };
  }

  closeRgpdModal() {
    this.setState({ rgpdModal: null });
  }

  componentDidMount() {
    Promise.all([
      // First, fetch the user
      this.props.onGetCompany(),
      this.props.onGetRgpd(),
      this.props.onGetUser(),
    ]).then(() => {
      // Next things to fetch
      if (!this.props.rgpd)
        this.setState({
          rgpdModal: <RgpdModal closeRgpdModal={() => this.closeRgpdModal()} />,
        });

      this.props.onGetCart();
      this.props.onGetNotifs();
      this.props.onGetCheckpoints();
      this.props.onGetCollaboratorTypes();
      this.props.onGetGeneralSettings();
      this.props.onGetAuditDMs();
      this.props.onGetMaintenances();

      //SOCKET GOOO
      let path = "/api/socket.io";

      if (
        typeof process.env.REACT_APP_RUNNING_MODE !== "undefined" &&
        process.env.REACT_APP_RUNNING_MODE === "dev"
      ) {
        path = "/socket.io";
      }

      const socket = new WebSocketStarter(
        Util.checkHttps(process.env.REACT_APP_BE_URL),
        path,
      );
      socket.start();
      //Permet d'envoyer et de récupérer les datas d'une company via la websocket.
      socket.emit("connect room", {
        data: {
          company_id: this.props.user.company_id,
          user_id: this.props.user._id,
        },
      });

      this.props.onGetSocket(socket);

      // Client specific
      if (this.props.user.role === Roles.CLIENT) {
        socket.on("update_order", () => {
          this.props.onGetOrders();
        });

        if (
          this.props.user.client_type !== "patient" &&
          this.props.user.client_type !== "helper" &&
          this.props.user.client_type !== "desk_user"
        ) {
          socket.on("update_coordination", (data) => {
            this.props.onGetAllMessages({ patient_id: data.patient_id });
          });
          socket.on("update_coordination_notifications", () => {
            this.props.onGetAllNotificationsPatients();
          });
          this.props.onGetAllNotificationsPatients();
        }

        this.props.onGetOrders();
        this.props.onGetOrdersSettings();
        this.props.onGetRequests();
        this.props.onGetMercurial();
        this.props.onGetAdmin();
        this.props.onGetClients();
        this.props.onGetPatients();
        this.props.onGetAudits();
        this.props.onGetPromoOperations();
        this.props.onGetInterventions();
        this.props.onGetPrescriberTypes();
      }

      // Admin & Sales rep
      if (
        this.props.user.role === Roles.SALES_REP ||
        this.props.user.role === Roles.ADMIN
      ) {
        socket.on("update_order", () => {
          this.props.onGetOrdersAdmin();
        });
        socket.on("update_coordination", (data) => {
          this.props.onGetAllMessages({ patient_id: data.patient_id });
        });
        socket.on("update_coordination_notifications", () => {
          this.props.onGetAllNotificationsPatients();
        });
        this.props.onGetAllNotificationsPatients();
        this.props.onGetAuditRooms();
        this.props.onGetOrdersAdmin();
        this.props.onGetClientsAdmin();
        this.props.onGetMercurials();
        this.props.onGetAllRequests();
        this.props.onGetOrdersSettingsAdmin();
        this.props.onGetProductsSettingsAdmin();
        this.props.onGetPatientsAdmin();
        this.props.onGetPrescriberTypes();
        this.props.onGetAuditSettings();
        this.props.onGetAuditsAdmin();
        this.props.onGetInterventionsAdmin();
        this.props.onGetAuditDeskSettings();
        this.props.onGetCollaborators();
      }

      // Admin specific
      if (this.props.user.role === Roles.ADMIN) {
        this.props.onGetPromoOperationsAdmin();
      }
    });
  }

  closeNotifModal(notif) {
    this.props.onDeleteNotif({ notifId: notif._id });
  }

  render() {
    const { user, notifications } = this.props;

    let notifModals = [];
    if (notifications.length !== 0) {
      for (let notif of notifications) {
        if (notif.subject === NotificationSubject.MERCURIAL_DELETE_OR_UPDATE) {
          notifModals.push(
            <NotificationModal
              key={notif._id}
              title={<FormattedMessage id="Notification.Mercurial.Upd.Title" />}
              content={
                <FormattedMessage id="Notification.Mercurial.Upd.Content" />
              }
              successCallback={() => this.closeNotifModal(notif)}
              closeModal={() => this.closeNotifModal(notif)}
            />,
          );
        } else if (notif.subject === NotificationSubject.PRODUCT_DELETED) {
          notifModals.push(
            <NotificationModal
              key={notif._id}
              title={<FormattedMessage id="Notification.Mercurial.Upd.Title" />}
              content={
                <FormattedMessage id="Notification.Product.Deleted.Content" />
              }
              successCallback={() => this.closeNotifModal(notif)}
              closeModal={() => this.closeNotifModal(notif)}
            />,
          );
        } else if (
          notif.subject === NotificationSubject.PRODUCT_ON_CART_UPDATED
        ) {
          notifModals.push(
            <NotificationModal
              key={notif._id}
              title={<FormattedMessage id="Notification.Mercurial.Upd.Title" />}
              content={
                <FormattedMessage id="Notification.Product.On.Cart.Updated.Content" />
              }
              successCallback={() => this.closeNotifModal(notif)}
              closeModal={() => this.closeNotifModal(notif)}
            />,
          );
        }
      }
    }

    return (
      <Container fluid className="bg-light d-flex flex-column min-vh-100 p-0">
        <MenuMAD />
        <SectionContainer>
          <SwitchComponent userRole={user.role} clientType={user.client_type} />
        </SectionContainer>
        {notifModals}

        {this.state.rgpdModal}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rgpd: state.rgpd,
    user: state.user,
    notifications: state.notifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetSocket: (data) => dispatch(getSocket(data)),
    onGetUser: () => dispatch(getUser()),
    onGetRgpd: () => dispatch(getRgpd()),
    onGetCart: () => dispatch(getCart()),
    onGetOrders: () => dispatch(getOrders()),
    onGetNotifs: () => dispatch(getNotifs()),
    onDeleteNotif: (data) => dispatch(deleteNotif(data)),
    onGetOrdersSettings: () => dispatch(getOrdersSettings()),
    onGetRequests: () => dispatch(getRequests()),
    onGetMercurial: () => dispatch(getMercurial()),
    onGetCollaboratorTypes: () => dispatch(getCollaboratorTypes()),
    onGetCheckpoints: () => dispatch(getCheckpoints()),
    onGetAdmin: () => dispatch(getAdmin()),
    onGetGeneralSettings: () => dispatch(getGeneralSettings()),
    onGetPatients: () => dispatch(getPatients()),
    onGetAudits: () => dispatch(getAudits()),
    onGetCompany: () => dispatch(getCompany()),
    onGetPromoOperations: () => dispatch(getPromoOperations()),
    onGetAuditDMs: () => dispatch(getAuditDMs()),
    onGetMaintenances: () => dispatch(getMaintenances()),
    onGetInterventions: () => dispatch(getInterventions()),
    onGetClients: () => dispatch(getClients()),
    onGetAllMessages: (data) => dispatch(getMessages(data)),
    //**Coordination Notifications */
    onGetAllNotificationsPatients: () => dispatch(messagesNotifsPatients()),
    // ADMIN & SALES_REP
    onGetAuditRooms: () => dispatch(getAuditRooms()),
    onGetOrdersAdmin: () => dispatch(getOrdersAdmin()),
    onGetClientsAdmin: () => dispatch(getClientsAdmin()),
    onGetMercurials: () => dispatch(getMercurialsAdmin()),
    onGetAllRequests: () => dispatch(getAllRequests()),
    onGetPatientsAdmin: () => dispatch(getPatientsAdmin()),
    onGetPrescriberTypes: () => dispatch(getPrescriberTypes()),
    onGetAuditSettings: () => dispatch(getAuditSettings()),
    onGetAuditsAdmin: () => dispatch(getAuditsAdmin()),
    onGetInterventionsAdmin: () => dispatch(getInterventionsAdmin()),
    onGetAuditDeskSettings: () => dispatch(getAuditDeskSettings()),

    // ADMIN only
    onGetOrdersSettingsAdmin: () => dispatch(getOrdersSettingsAdmin()),
    onGetProductsSettingsAdmin: () => dispatch(getProductsSettingsAdmin()),
    onGetCollaborators: () => dispatch(getCollaborators()),
    onGetPromoOperationsAdmin: () => dispatch(getPromoOperationsAdmin()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
