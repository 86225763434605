import React from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import Icon from "../Icon";
import MenuButton from "../bootstrap/MenuButton";
class NotificationModal extends React.Component {
  close() {
    this.props.closeModal();
  }

  confirm() {
    if (this.props.successCallback) this.props.successCallback();
    this.close();
  }

  switchTextColor(variant) {
    let color;
    switch (variant) {
      case "danger":
      case "secondary":
      case "dark":
      case "success":
        color = "text-white";
        break;

      default:
        break;
    }

    return color;
  }

  render() {
    const { size, title, content, buttonLabel } = this.props;
    let context = this.props.context || "";

    return (
      <Modal
        show={true}
        onHide={() => this.close()}
        variant={context}
        size={size}
      >
        <Modal.Header closeButton>
          <Modal.Title className={this.switchTextColor(context)}>
            <Icon icon="info-circle" size="2xl" className="me-3" />
            {title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>{content}</Modal.Body>

        <Modal.Footer>
          <MenuButton variant={context} onClick={() => this.confirm()}>
            {buttonLabel ? buttonLabel : <FormattedMessage id="Ok" />}
          </MenuButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default NotificationModal;
