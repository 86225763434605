import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Paginator from "../sub/Paginator";
import PrescriberRow from "./PrescriberRow";
import Roles from "../../enums/Roles";
import Util from "../../util/Util";
import PrescriberModal from "./PrescriberModal";
import { getClient, importClients } from "../../actions/clients/clients";
import MenuButton from "../sub/bootstrap/MenuButton";
import InputLength from "../../enums/InputLength";
import InputTextElement from "../sub/InputTextElement";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import { Col, Form, FormSelect, Row } from "react-bootstrap";
import ExcelUtil from "../../util/ExcelUtil";
import { NotificationManager } from "react-notifications";
import ImportExcelFileModal from "../sub/modals/ImportExcelFileModal";
import ImportExcelFileColumnsModal from "../sub/modals/ImportExcelFileColumnsModal";
import APIUrl from "../../APIUrl";
import ObjectUtil from "../../util/ObjectUtil";
import ClientMapping from "../../util/ClientMapping";
import { checkIfEmailIsValid } from "../../actions/user/user";
import ActionMenu from "../sub/ActionMenu";

class Prescribers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,

      // filters
      nameFilter: "",
      client_typeFilter: this.props.client_typeFilter || "",
      typeFilter: "",
      addressFilter: "",
    };

    this.paginator = new Paginator(this);
  }

  openModal(client, target = "clientInfo") {
    const modal = (client) => {
      this.setState({
        modal: (
          <PrescriberModal
            isOpen={true}
            client={client}
            target={target}
            openModal={(client, target) => this.openModal(client, target)}
            close={() => this.closeModal()}
          />
        ),
      });
    };

    if (client && client._id) {
      this.props.onGetClient(client._id, (client) => modal(client));
    } else {
      modal(client);
    }
  }

  closeModal() {
    this.setState({ modal: null });
  }

  areResultsFiltered = () => {
    if (
      !Util.emptyString(this.state.nameFilter) ||
      !Util.emptyString(this.state.client_typeFilter) ||
      !Util.emptyString(this.state.typeFilter) ||
      !Util.emptyString(this.state.addressFilter)
    ) {
      return true;
    } else {
      return false;
    }
  };

  resetSearchFields() {
    this.setState({
      nameFilter: "",
      client_typeFilter: "",
      typeFilter: "",
      addressFilter: "",
    });
  }

  // Reset all previous import params in state when aborting import process
  abortAndCloseModal() {
    this.setState({
      fileName: null,
      fileData: null,
    });

    this.closeModal();
  }

  /**
   * Performs checks upon data.
   * We try to avoid sending data that will be rejected by the backend (because of Mongo/Mongoose field types for example)
   *
   * @param {*} columnsReferenceList
   */
  async checkDataIntegrity(fileData, columnsReferenceList, maxErrorToDisplay) {
    let errorsFound = [];
    const itemMapping = ClientMapping;
    var columns = {};

    // Store association between required column and matching column in the file (labels can be different if we didnt used automatching or partial automatching)
    for (let key of Object.keys(itemMapping)) {
      columns[key] = itemMapping[key];
    }

    let currentError;

    // Default maxlength for a value (if checked)
    // May be locally changed on some values
    let maxlength;

    // Add an error to the stack
    const addError = (error) => {
      if (Util.typeOf(error) === "Object") {
        errorsFound.push(error);
      }
    };

    // Loop through file rows
    for (let row of fileData) {
      if (errorsFound.length === maxErrorToDisplay) {
        break;
      }

      // Get current row keys
      let currentRowKeys = Object.keys(row);

      /**
       * CHECK SPECIAL FIELDS (where we know that value must be an integer or a float for example)
       */
      for (let key of currentRowKeys) {
        if (errorsFound.length === maxErrorToDisplay) {
          break;
        }

        let itemMappingReferenceKey = ObjectUtil.getKeyByValue(
          columnsReferenceList,
          key,
        );

        if (
          (itemMappingReferenceKey === "account_type" &&
            Util.emptyString(row[key])) ||
          (itemMappingReferenceKey === "client_type" &&
            Util.emptyString(row[key])) ||
          (itemMappingReferenceKey === "name" && Util.emptyString(row[key])) ||
          (itemMappingReferenceKey === "first_name" &&
            Util.emptyString(row[key])) ||
          (itemMappingReferenceKey === "email" && Util.emptyString(row[key]))
        ) {
          currentError = {
            numRow: row.__rowNum__ + 1,
            field: key,
            targetField: itemMapping[itemMappingReferenceKey],
            value: row[key],
            hint: <FormattedMessage id="Field.Cant.Be.Empty" />,
          };

          addError(currentError);
          break;
        }

        switch (true) {
          // Perform tests on postal code field
          case itemMappingReferenceKey === "postal_code":
            // We allow those fields to be bypassed.
            // To allow this, we must provide a dash caracter ("-") in the selected field
            if (!Util.emptyString(row[key]) && !Util.isPostal(row[key])) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Valid.Postal.Code"
                    values={{ value: row[key] }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          // Perform tests on phone fields
          case itemMappingReferenceKey === "phone":
          case itemMappingReferenceKey === "mobile":
            // We allow those fields to be bypassed.
            // To allow this, we must provide a dash caracter ("-") in the selected field
            if (!Util.emptyString(row[key]) && !Util.isPhone(row[key])) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Valid.Phone.Number"
                    values={{ value: row[key] }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          // Define default value for those fields (might be required later in email check for instance)
          case itemMappingReferenceKey === "notifyByEmail":
            if (Util.emptyString(row[key])) {
              row[itemMapping[itemMappingReferenceKey]] = "Non";
            }
            break;

          // Perform tests on email field
          case itemMappingReferenceKey === "email":
            maxlength = InputLength.EMAIL;

            if (Util.emptyString(row[key])) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Error.Account.Email.Required"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            }

            if (!Util.emptyString(row[key])) {
              this.props.onCheckIfEmailIsValid(row[key], null, (response) => {
                let currentError = {
                  numRow: row.__rowNum__ + 1,
                  field: key,
                  targetField: itemMapping[itemMappingReferenceKey],
                  value: row[key],
                  hint: (
                    <span>
                      <FormattedMessage id="Invalid.Email.Error" /> (
                      {response.data.reason})
                    </span>
                  ),
                };
                addError(currentError);
              });
            }

            if (row[key].length > maxlength) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Error.Maxlength"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            } else if (!Util.emptyString(row[key]) && !Util.isEmail(row[key])) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Valid.Email.address"
                    values={{ value: row[key] }}
                  />
                ),
              };
              addError(currentError);
            }

            break;

          case itemMappingReferenceKey === "name":
          case itemMappingReferenceKey === "first_name":
            maxlength = InputLength.NAME;
            // Check maxlength
            if (!Util.safeGenericString(row[key], 1, maxlength)) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Valid.Data"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          case itemMappingReferenceKey === "address":
          case itemMappingReferenceKey === "addressAdditionnal":
          case itemMappingReferenceKey === "city":
            maxlength = InputLength.ADDRESS;
            // Check maxlength
            if (
              !Util.emptyString(row[key]) &&
              !Util.safeGenericString(row[key], 1, maxlength)
            ) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Valid.Data"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          case itemMappingReferenceKey === "type":
          case itemMappingReferenceKey === "mercurial_Id":
            maxlength = InputLength.ID;
            // Check maxlength
            if (
              !Util.emptyString(row[key]) &&
              !Util.safeGenericString(row[key], 1, maxlength)
            ) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Valid.Data"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          default:
            // Convert field to string to evaluate it
            let fieldValue = row[key].toString();
            // Double check string fields that may contain only spaces (so they are not considered as empty)
            // We trim the value in order to catch'em as well eventually
            if (fieldValue.trim() === "") {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: "",
                hint: <FormattedMessage id="Field.Cant.Be.Empty" />,
              };

              addError(currentError);
            }
            break;
        }
      }
    }

    return errorsFound;
  }

  // Step 1 : Import excel file
  initStep1(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      mode: "create",
      modal: (
        <ImportExcelFileModal
          title={<FormattedMessage id="Import.Clients" />}
          templateFileUrl={APIUrl.getClientsTemplate}
          templateFileName={"template_clients"}
          closeModal={() => this.abortAndCloseModal()}
          onComplete={(fileData) => this.initStep2(fileData)}
        />
      ),
    });
  }

  // Step 2 : File column mapping and data validation
  initStep2(file) {
    // Parse the excel file and then move on to next modal
    ExcelUtil.parse(file, (fileData) => {
      // Save the passed params
      this.setState({
        fileName: file.name,
        fileData: fileData,
      });

      // Close current modal
      this.closeModal();

      // Open next step modal
      this.setState({
        modal: (
          <ImportExcelFileColumnsModal
            closeModal={() => this.abortAndCloseModal()}
            fileData={fileData}
            onComplete={(columns) => this.initStep3(columns)}
            title={<FormattedMessage id="Import.Clients" />}
            itemMapping={ClientMapping}
            checkDataIntegrity={(columnsReferenceList, maxErrorToDisplay) =>
              this.checkDataIntegrity(
                fileData,
                columnsReferenceList,
                maxErrorToDisplay,
              )
            }
          />
        ),
      });
    });
  }

  // Step 3 : Send data to the backend
  initStep3(columns) {
    this.setState({
      columns: columns,
    });

    let successCallback;

    successCallback = () => {
      // Close current modal
      this.closeModal();

      // Notification
      let errorMessage = this.props.intl.formatMessage({
        id: "Import.Successful",
      });

      NotificationManager.success(errorMessage);
    };

    // Send the clients to the BE

    // First, fix data with correct columns
    const clients = this.fixClientsCols(this.state.fileData, columns);

    this.props.onImportClients(
      {
        fileName: this.state.fileName,
        clients: clients,
      },
      successCallback,
    );
  }

  // Perform checks on columns <-> data associations for clients
  fixClientsCols(clients, columns) {
    let newClients = [];
    for (let e of clients) {
      var newPatient = {};
      for (let col of Object.keys(columns)) newPatient[col] = e[columns[col]];
      newClients.push(newPatient);
    }

    return newClients;
  }

  renderActionMenu = () => {
    // Hide from client role
    if (this.props.clientId) return;

    let menuAction;
    let menuItems = [];

    menuItems.push({
      icon: "file-circle-plus",
      action: () => this.openModal(),
      text: <FormattedMessage id="Add.Client" />,
    });

    menuItems.push({
      icon: "upload",
      action: (e) => this.initStep1(e),
      text: <FormattedMessage id="Import.Clients" />,
    });

    menuAction = (
      <ActionMenu
        items={menuItems}
        icon="circle-plus"
        className="ms-1"
        size="2xl"
        label={<FormattedMessage id="Actions" />}
      />
    );

    return menuAction;
  };

  render() {
    const { user, patient, collaborator } = this.props;

    const clients = this.props.clients.filter(
      (c) => c.client_type !== "patient",
    );

    let clientsType = this.props.prescriberTypes.map((prescriber) => {
      return (
        <option key={prescriber._id} value={prescriber._id}>
          {prescriber.name}
        </option>
      );
    });

    // No clients
    if ((!clients || clients.length === 0) && user.role !== Roles.CLIENT) {
      return (
        <TableToolbar message={<FormattedMessage id="Empty.Client" />}>
          {!this.props.limit && this.renderActionMenu()}
          {this.state.modal}
        </TableToolbar>
      );
    }

    this.paginator.init(clients.length);

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    let i = 0;
    let clientsNode = clients.map((client) => {
      if (patient && patient.helpers_ids && patient.helpers_ids.length > 0) {
        if (patient.helpers_ids.includes(client._id)) return null;
      }

      if (
        patient &&
        patient.prescribers_ids &&
        patient.prescribers_ids.length > 0
      ) {
        if (patient.prescribers_ids.includes(client._id)) return null;
      }

      // Specific to collaborator assignments
      if (
        collaborator &&
        collaborator.prescribers &&
        collaborator.prescribers.length > 0
      ) {
        if (
          collaborator.prescribers.find((c) => c.prescriber_id === client._id)
        )
          return null;
      }

      if (this.state.nameFilter && this.state.nameFilter !== "") {
        if (
          client.name
            .toUpperCase()
            .indexOf(this.state.nameFilter.toUpperCase()) === -1 &&
          client.first_name
            .toUpperCase()
            .indexOf(this.state.nameFilter.toUpperCase()) === -1
        )
          return null;
      }

      if (this.state.client_typeFilter && this.state.client_typeFilter !== "") {
        if (
          typeof client.client_type === "undefined" ||
          client.client_type.indexOf(this.state.client_typeFilter) === -1
        )
          return null;
      }

      if (this.state.typeFilter && this.state.typeFilter !== "") {
        /**
         * Important: Check if client.type is defined before trying to filter to avoid
         * "TypeError: Cannot read property 'indexOf' of undefined"
         */
        if (
          typeof client.type === "undefined" ||
          !client.type ||
          client.type.indexOf(this.state.typeFilter) === -1
        )
          return null;
      }

      if (this.state.addressFilter && this.state.addressFilter !== "") {
        if (
          (!client.address ||
            client.address
              .toUpperCase()
              .indexOf(this.state.addressFilter.toUpperCase()) === -1) &&
          (!client.postal_code ||
            client.postal_code
              .toUpperCase()
              .indexOf(this.state.addressFilter.toUpperCase()) === -1) &&
          (!client.city ||
            client.city
              .toUpperCase()
              .indexOf(this.state.addressFilter.toUpperCase()) === -1)
        )
          return null;
      }

      if (this.props.limit && ++i > this.props.limit) return null;

      if (!this.paginator.keep()) return null;

      return (
        <PrescriberRow
          key={client._id}
          client={client}
          displayAPIModal={(prescriber) =>
            this.openModal(prescriber, "apiConfiguration")
          }
          edit={(client, tabName) => this.openModal(client, tabName)}
          user={user}
          limit={this.props.limit && true}
          // delete={(client) => this.delete(client)}
        />
      );
    });

    // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
    // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
    const { enabled: crmEnabled = false, software: crmSoftware = null } = this
      .props.company.crm
      ? this.props.company.crm
      : {};

    return (
      <React.Fragment>
        {!this.props.limit && (
          <TableToolbar>
            <Form.Group as={Row} className="align-items-center">
              <Col>
                <InputTextElement
                  id="search_client"
                  type="text"
                  className={this.state.nameFilter && "filtered-result"}
                  placeholder={
                    this.props.intl.formatMessage({ id: "Name" }) +
                    " " +
                    this.props.intl.formatMessage({ id: "Or" }) +
                    " " +
                    this.props.intl.formatMessage({ id: "First.Name" })
                  }
                  onChange={(e) => {
                    this.setState({ nameFilter: e.target.value });
                  }}
                  disabled={disableFormInput}
                  value={this.state.nameFilter}
                />
              </Col>
              <Col>
                <FormSelect
                  className={this.state.client_typeFilter && "filtered-result"}
                  id="search_type"
                  onChange={(e) => {
                    this.setState({ client_typeFilter: e.target.value });
                  }}
                  disabled={disableFormInput || this.props.client_typeFilter}
                  value={this.state.client_typeFilter}
                >
                  <option value="">
                    {this.props.intl.formatMessage({ id: "Type" })}
                  </option>
                  <option value="desk_user">
                    {this.props.intl.formatMessage({ id: "Desk" })}
                  </option>
                  <option value="helper">
                    {this.props.intl.formatMessage({ id: "Individual" })}
                  </option>
                  <option value="prescriber">
                    {this.props.intl.formatMessage({ id: "Professional" })}
                  </option>
                </FormSelect>
              </Col>
              <Col>
                <FormSelect
                  id="search_label"
                  className={this.state.typeFilter && "filtered-result"}
                  onChange={(e) => {
                    this.setState({ typeFilter: e.target.value });
                  }}
                  disabled={disableFormInput}
                  value={this.state.typeFilter}
                >
                  <option value="">
                    {this.props.intl.formatMessage({ id: "Category" })}
                  </option>
                  {clientsType}
                </FormSelect>
              </Col>
              <Col>
                <InputTextElement
                  id="adress"
                  type="text"
                  placeholder={this.props.intl.formatMessage({ id: "Address" })}
                  maxLength={InputLength.ADDRESS}
                  className={this.state.addressFilter && "filtered-result"}
                  onChange={(e) => {
                    this.setState({ addressFilter: e.target.value });
                  }}
                  disabled={disableFormInput}
                  value={this.state.addressFilter}
                />
              </Col>
              <Col>
                <MenuButton
                  onClick={() => this.resetSearchFields()}
                  hover={
                    this.areResultsFiltered() &&
                    !disableFormInput && <FormattedMessage id="Remove.Filter" />
                  }
                  variant={
                    this.areResultsFiltered() ? "info" : "outline-secondary"
                  }
                  icon="filter"
                  className="me-1"
                  disabled={!this.areResultsFiltered() || disableFormInput}
                />
              </Col>
              <Col className="text-end">
                {user.role !== Roles.CLIENT && this.renderActionMenu()}
              </Col>
            </Form.Group>
          </TableToolbar>
        )}

        <table
          className={
            this.props.limit
              ? "table table-striped tablee4mad pointer-events-none"
              : "table table-striped tablee4mad"
          }
        >
          {clients.length > 0 && (
            <thead>
              <tr>
                <th scope="col">
                  <FormattedMessage id="Name" />
                </th>
                <th scope="col">
                  <FormattedMessage id="Type" />
                </th>
                <th scope="col">
                  <FormattedMessage id="Category" />
                </th>
                <th scope="col">
                  <FormattedMessage id="Address" />
                </th>
                {!this.props.limit && (
                  <th scope="col" className="text-center">
                    <FormattedMessage id="Nbr.Patients" />
                  </th>
                )}
                {!this.props.limit &&
                  (this.props.user.access_pharmamad ||
                    this.props.user.access_module_store) && (
                    <th scope="col">
                      <FormattedMessage id="Mercurial.Linked" />
                    </th>
                  )}
                {user.role !== Roles.CLIENT && crmEnabled && (
                  <th scope="col" className="text-center">
                    <FormattedMessage
                      id="API.CRM.App.Link"
                      values={{ crmSoftware: crmSoftware }}
                    />
                  </th>
                )}
                {!this.props.limit && user.role !== Roles.CLIENT && (
                  <th scope="col" className="text-center">
                    <FormattedMessage id="Actions" />
                  </th>
                )}
              </tr>
            </thead>
          )}
          <tbody>{clientsNode}</tbody>
        </table>

        {!this.props.limit && this.paginator.render()}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clients,
    user: state.user,
    prescriberTypes: state.prescriberTypes,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetClient: (clientId, successCallback) =>
      dispatch(getClient(clientId, successCallback)),
    onImportClients: (data, successCallback, failureCallback) =>
      dispatch(importClients(data, successCallback, failureCallback)),
    onCheckIfEmailIsValid: (email, isValidCallback, notValidCallback) =>
      dispatch(checkIfEmailIsValid(email, isValidCallback, notValidCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Prescribers));
