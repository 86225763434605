import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import Select from "react-select";
import Util from "../../../util/Util";
import { connect } from "react-redux";
import User from "../../../interfaces/User";

interface AssignOrderModalProps {
  clients: Array<User>;
  assignOrder: (clientId: string) => void;
  closeModal: () => void;
}

function AssignOrderModal({
  clients,
  assignOrder,
  closeModal,
}: AssignOrderModalProps) {
  const [selectedClient, setSelectedClient] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const intl = useIntl();

  const getSelectOptions = () => {
    const options = [];

    for (const c of clients) {
      if (c.client_type === "desk_user") continue;
      options.push({
        value: c._id,
        label: Util.formatFullName(c.first_name, c.name),
      });
    }
    return options;
  };

  return (
    <Modal show={true} onHide={closeModal} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="Assign.To.Client" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Select
          placeholder={intl.formatMessage({ id: "Select.Client" })}
          options={getSelectOptions()}
          noOptionsMessage={() => <FormattedMessage id={"No.Result"} />}
          onChange={(option) => setSelectedClient(option)}
          value={selectedClient}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={!selectedClient || !selectedClient.value}
          onClick={() =>
            selectedClient &&
            selectedClient.value &&
            assignOrder(selectedClient.value)
          }
        >
          <FormattedMessage id="Assign" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state: any) => {
  return {
    clients: state.clients,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignOrderModal);
