import React from "react";
import { connect } from "react-redux";
import DateUtil from "../../../util/DateUtil";
import APIUrl from "../../../APIUrl";
import { FormattedMessage, injectIntl } from "react-intl";
import StringUtil from "../../../util/StringUtil";
import Footer from "../../template/Footer";
import Util from "../../../util/Util";

class DeskTemplatePDF extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prescriber: "",
      helper: "",
      user: "",
    };
  }

  render() {
    const {
      user,
      id,
      helper,
      auditSettings,
      prescriber,
      patient,
      patient_analysis,
      deskStore,
    } = this.props;

    const prepare_analysis = () => {
      const age = DateUtil.calculateAge(patient.birth_date);
      let gender_info = "";
      let age_info = "";
      let height_info = "";
      let weight_info = "";
      let shoesize_info = "";
      let patient_name = "";

      gender_info = (patient_analysis.gender === "1" ||
        patient_analysis.gender === "2") && (
        <strong>
          <FormattedMessage
            id={patient_analysis.gender === "1" ? "A.Man" : "A.Woman"}
          />
        </strong>
      );

      age_info = age && (
        <>
          <FormattedMessage
            id={patient_analysis.gender === "1" ? "Old.Male" : "Old.Female"}
          />{" "}
          <strong>
            {age} <FormattedMessage id="Years" />
          </strong>
        </>
      );

      height_info =
        patient_analysis.height !== 0 ? (
          <FormattedMessage
            id={gender_info || age_info ? "And.Measuring" : "Measuring"}
            values={{
              height: (
                <strong>
                  {StringUtil.convertHeight(patient_analysis.height)}
                </strong>
              ),
            }}
          />
        ) : (
          ""
        );

      weight_info =
        patient_analysis.weight !== 0 ? (
          <FormattedMessage
            id={
              gender_info || age_info || height_info ? "And.Weight" : "Weights"
            }
            values={{
              weight: (
                <strong>
                  {patient_analysis.weight} <FormattedMessage id="Kg" />
                </strong>
              ),
            }}
          />
        ) : (
          ""
        );

      shoesize_info =
        patient_analysis.shoesize !== 0 ? (
          <FormattedMessage
            id={gender_info || age_info ? "And.Shoe" : "Shoe.Size.Of"}
            values={{ shoesize: patient_analysis.shoesize }}
          />
        ) : (
          ""
        );

      patient_name = Util.formatFullName(patient.first_name, patient.name);

      let detailed_analysis_list = "";
      if (
        patient_analysis.incontinence !== "" ||
        patient_analysis.bedsores !== "" ||
        patient_analysis.homecare_workers !== "" ||
        patient_analysis.home_accessibility !== ""
      ) {
        let incontinence_sentence = "";
        let bedsores_sentence = "";
        let homecare_workers_sentence = "";

        incontinence_sentence =
          patient_analysis.incontinence !== "" ? (
            <li>
              <FormattedMessage
                id={
                  "Urinary.Incontinence.Sentence." +
                  patient_analysis.incontinence
                }
              />
            </li>
          ) : (
            ""
          );

        bedsores_sentence =
          patient_analysis.bedsores !== "" ? (
            <li>
              <FormattedMessage
                id={"Bedsore.Risk.Sentence." + patient_analysis.bedsores}
              />
            </li>
          ) : (
            ""
          );

        homecare_workers_sentence =
          patient_analysis.homecare_workers !== "" ? (
            <li>
              <FormattedMessage
                id={
                  "Homecare.Workers.Sentence." +
                  patient_analysis.homecare_workers
                }
              />
            </li>
          ) : (
            ""
          );

        detailed_analysis_list = (
          <p>
            <FormattedMessage id="Patient.Analysis.Sentence.2" />
            <ul>
              {incontinence_sentence}
              {bedsores_sentence}
              {homecare_workers_sentence}
            </ul>
          </p>
        );
      }

      return (
        <>
          <div className="analysis">
            <h4>
              <FormattedMessage id="Patient.About" />
            </h4>
            {(gender_info ||
              age_info ||
              height_info ||
              weight_info ||
              shoesize_info) && (
              <p>
                <FormattedMessage
                  id="Patient.Analysis.Sentence.1"
                  values={{
                    gender_and_age: (
                      <>
                        {(gender_info || age_info) && (
                          <FormattedMessage id="Is" />
                        )}{" "}
                        {gender_info} {age_info}
                      </>
                    ),
                    height: height_info,
                    weight: weight_info,
                    shoesize: shoesize_info,
                  }}
                />
              </p>
            )}
            {detailed_analysis_list}
            <p>
              <FormattedMessage
                id="Patient.Analysis.Sentence.3"
                values={{ patient_name: patient_name }}
              />
            </p>
          </div>
        </>
      );
    };

    var imgError = false;
    // Add / Reformat DATAS product info + relatedProducts

    // ELEMENTS / PRODUCTS TO RENDER

    let elementsNodes;

    if (deskStore && deskStore.length > 0) {
      let pIndex = 0;

      elementsNodes = deskStore.map((product, idx) => {
        var imgError = false;
        var imgSrc =
          APIUrl.getDeskProductImg + product._id + "?token=" + APIUrl.jwtToken;

        var imgProduct = (
          <img
            src={imgSrc}
            alt={"desk_product_img"}
            onError={(e) => {
              if (imgError) return;
              e.target.onerror = null;
              imgError = true;
              e.target.src = "/images/placeholder_120x120.png";
            }}
          />
        );

        let logotrefundable = "";
        if (product.refundable === true) {
          logotrefundable = <div className="pictogram avis_medical"></div>;
        }

        if (product.vte_loc === "Location") {
          var codeline = <div className="pictogram location"></div>;
        } else {
          codeline = <div className="pictogram vente"></div>;
        }

        if (pIndex === 0) {
          pIndex++;
          return (
            <div key={idx} className="main_product">
              <div className="title">
                <FormattedMessage id="Selection.Mandatory" /> : {product.name}
              </div>
              <div style={{ padding: "3mm" }}>
                <div className="image">
                  {logotrefundable}
                  {codeline}
                  {imgProduct}
                </div>
                <div className="description">
                  {product.description_text}
                  {product.comment && product.comment !== "" && (
                    <div className="comment">
                      <strong>
                        <FormattedMessage id="Comment" /> :{" "}
                      </strong>
                      {product.comment}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="product" key={"pdfproduct" + product._id}>
              <div className="title">{product.name}</div>
              <div className="image">
                {logotrefundable}
                {codeline}
                {imgProduct}
              </div>
              <div className="description">
                {product.description_text}
                {product.comment && product.comment !== "" && (
                  <div className="comment">
                    <strong>
                      <FormattedMessage id="Comment" /> :{" "}
                    </strong>
                    {product.comment}
                  </div>
                )}
              </div>
            </div>
          );
        }
      });
    }

    let mainProduct = elementsNodes;
    let otherProducts;

    if (elementsNodes && elementsNodes.length > 1) {
      mainProduct = elementsNodes[0];
      otherProducts = elementsNodes.slice(1);
      otherProducts = (
        <div className="related_products">
          <h2 className="related_title">
            <FormattedMessage id="Other.Recommended.Products" />
          </h2>
          {otherProducts}
        </div>
      );
    }

    // RENDER PDF TEMPLATE
    return (
      <div id={id}>
        <div id="header">
          <table>
            <tbody>
              <tr>
                <td id="pharma_logo_td">
                  {this.props.generalSettings.logo && (
                    <img
                      id="pharma_logo"
                      src={`${APIUrl.getGeneralSettingsLogo}${this.props.user.company_id}/${this.props.generalSettings._id}`}
                      alt="logo client"
                      onError={(e) => {
                        if (imgError) return;
                        e.target.onerror = null;
                        imgError = true;
                        e.target.src = "/images/placeholder_120x120.png";
                      }}
                    />
                  )}
                  <div className="titulaire">
                    <p>
                      <FormattedMessage id="Date" /> :{" "}
                      {DateUtil.toDate(Date.now())}
                      <br />
                      <FormattedMessage id="Audit.Made.By" /> :{" "}
                      {Util.formatFullName(user.first_name, user.name)}
                    </p>
                  </div>
                </td>
                <td id="app_logo_td">
                  <img
                    id="app_logo"
                    src={APIUrl.rootUrl + "/img/pharmamad_logo.svg"}
                    alt=""
                  />
                  <div id="audit_infos">
                    <p>
                      <FormattedMessage id="Patient" /> :{" "}
                      {Util.formatFullName(patient.first_name, patient.name)}
                      <br />
                      {patient.address}
                      <br />
                      {patient.postal_code || ""} {patient.city || ""}
                    </p>
                    {helper?.name && (
                      <p>
                        <FormattedMessage id="Helper" /> :{" "}
                        {Util.formatFullName(helper.first_name, helper.name)}
                        <br />
                      </p>
                    )}
                    {prescriber?.name && (
                      <p>
                        <FormattedMessage id="Prescriber" /> :{" "}
                        {Util.formatFullName(
                          prescriber.first_name,
                          prescriber.name,
                        )}
                        <br />
                      </p>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="no-print">
          <img src={APIUrl.rootUrl + "/img/medical_advice.svg"} alt="" />
          <img src={APIUrl.rootUrl + "/img/picto_location.svg"} alt="" />
          <img src={APIUrl.rootUrl + "/img/picto_vente.svg"} alt="" />
        </div>

        {auditSettings.title ? (
          <h1 id="audit_title">{auditSettings.title.toUpperCase()}</h1>
        ) : (
          ""
        )}

        {prepare_analysis()}

        <div id="warning">{auditSettings.rationale}</div>
        {mainProduct}
        {otherProducts}

        <Footer generalSettings={this.props.generalSettings} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DeskTemplatePDF));
