import { Col, Image, Row } from "react-bootstrap";

const LoadingSpinner = () => {
  return (
    <Row>
      <Col className="d-flex justify-content-center p-3">
        <Image src="/images/loading.gif" />
      </Col>
    </Row>
  );
};

export default LoadingSpinner;
