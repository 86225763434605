import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Col, Form, FormSelect, Modal, Row } from "react-bootstrap";
import Util from "../../util/Util";
import DateUtil from "../../util/DateUtil";
import { updPatientAdmin } from "../../actions/patients/patients";
import EqSellModes from "../../enums/EqSellModes";
import CustomLabel from "../sub/CustomLabel";
import InputLength from "../../enums/InputLength";
import InputTextElement from "../sub/InputTextElement";
import MenuButton from "../sub/bootstrap/MenuButton";
import Select from "react-select";

class AddPatientEquipmentModal extends React.Component {
  constructor(props) {
    super(props);

    const { equipment } = this.props;

    this.isUpdate = !!equipment;

    this.state = {
      audit_equipment_id: "",
      mode: EqSellModes.SALE,
      first_install_date: "",
      last_prescription_date: "",
      rental_renewal: 1,
      internal_ref: "",
      serial_nbr: "",
      selectedEquipment: null,

      first_install_date_error: null,
      last_prescription_date_error: null,

      loading: false,
    };

    if (equipment) {
      this.state = {
        audit_equipment_id: equipment.audit_equipment_id,
        mode: "" + equipment.mode,
        first_install_date: DateUtil.toyyyyMMdd(equipment.first_install_date),
        last_prescription_date: DateUtil.toyyyyMMdd(
          equipment.last_prescription_date,
        ),
        rental_renewal: equipment.rental_renewal
          ? parseInt(equipment.rental_renewal)
          : 1,
        internal_ref: equipment.internal_ref ? equipment.internal_ref : "",
        serial_nbr: equipment.serial_nbr ? equipment.serial_nbr : "",
        selectedEquipment: {
          value: equipment.audit_equipment_id,
          label: this.getAuditEquipmentName(equipment.audit_equipment_id),
        },

        loading: false,
      };
    }
  }

  getAuditEquipmentName(equipmentId) {
    const dm = this.props.auditEquipments.find((dm) => dm._id === equipmentId);

    return dm ? dm.name : "-";
  }

  onSubmit() {
    this.setState({ loading: true });

    const equipment = {
      audit_equipment_id: this.state.audit_equipment_id,
      mode: this.state.mode,
      first_install_date: this.state.first_install_date,
      last_prescription_date: this.state.last_prescription_date,
      rental_renewal: this.state.rental_renewal,
      internal_ref: this.state.internal_ref,
      serial_nbr: this.state.serial_nbr,
    };

    const updatedPatient = {
      _id: this.props.patient._id,
      equipments: this.props.patient.equipments,
    };

    if (!updatedPatient.equipments) updatedPatient.equipments = [];
    updatedPatient.equipments.push(equipment);

    // Send to BE
    this.props.onUpdPatientAdmin(updatedPatient, () => this.props.close());
  }

  onChange(field, value) {
    if (field === "rental_renewal") {
      if (value >= 1 && value <= 12) {
        this.onBlur(field, value);
      } else {
        return;
      }
    } else if (field === "audit_equipment_id" && this.isUpdate) {
      this.onBlur(field, value);
    }

    this.setState({ [field]: value });
  }

  onBlur(field, value) {
    const { patient, equipment } = this.props;

    if (
      !this.isUpdate ||
      !patient ||
      !equipment ||
      (!value && field === "audit_equipment_id")
    )
      return;

    const updatedPatient = {
      _id: this.props.patient._id,
      equipments: this.props.patient.equipments,
    };

    for (let e of updatedPatient.equipments) {
      if (e._id === equipment._id) {
        e[field] = value;
      }
    }

    // Send to BE
    this.props.onUpdPatientAdmin(updatedPatient);
  }

  disabled() {
    return (
      Util.emptyString(this.state.audit_equipment_id) ||
      Util.emptyString(this.state.mode) ||
      //Util.emptyString(this.state.last_prescription_date) ||
      this.state.dateError ||
      this.state.loading
    );
  }

  getSelectOptions() {
    return this.props.auditRooms
      .map((room) => {
        let currentRoomDM = this.props.auditEquipments
          .filter((DM) => DM.currentRoom._id === room._id)
          .map((DM) => {
            return {
              value: DM._id,
              label: DM.name,
            };
          });

        if (currentRoomDM.length) {
          return {
            label: room.name,
            options: currentRoomDM,
          };
        }

        return null;
      })
      .filter((room) => room !== null);
  }

  render() {
    if (!this.props.auditEquipments) return null;

    return (
      <Modal
        show={true}
        onHide={() => this.props.close()}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Equipments" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group as={Row} className="mb-3 align-items-center">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Equipment" })}
              htmlFor="audit_equipment_id"
              displayAsCol
              required
            />
            <Col md={7}>
              <Select
                placeholder={<FormattedMessage id={"Select.DM"} />}
                options={this.getSelectOptions()}
                isClearable
                noOptionsMessage={() => <FormattedMessage id={"No.Result"} />}
                onChange={(option) => {
                  this.setState({
                    selectedEquipment: option,
                  });
                  this.onChange(
                    "audit_equipment_id",
                    option ? option.value : "",
                  );
                }}
                value={this.state.selectedEquipment}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 align-items-center">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Intern.Ref" })}
              htmlFor="internal_ref"
              displayAsCol
            />
            <Col md={7}>
              <InputTextElement
                type="text"
                id="internal_ref"
                maxLength={InputLength.ID}
                value={this.state.internal_ref}
                onChange={(e) => this.onChange("internal_ref", e.target.value)}
                onBlur={(e) => this.onBlur("internal_ref", e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 align-items-center">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Serial.Number" })}
              htmlFor="serial_nbr"
              displayAsCol
            />
            <Col md={7}>
              <InputTextElement
                type="text"
                id="serial_nbr"
                maxLength={InputLength.ID}
                value={this.state.serial_nbr}
                onChange={(e) => this.onChange("serial_nbr", e.target.value)}
                onBlur={(e) => this.onBlur("serial_nbr", e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 align-items-center">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Mode" })}
              htmlFor="mode"
              displayAsCol
              required
            />
            <Col md={7}>
              <FormSelect
                id="mode"
                value={this.state.mode}
                disabled={this.props.year}
                onBlur={(e) => this.onBlur("mode", e.target.value)}
                onChange={(e) => this.onChange("mode", e.target.value)}
              >
                {Object.values(EqSellModes).map((EqSellMode) => {
                  if (EqSellMode === 2) return null;
                  return (
                    <option key={EqSellMode} value={EqSellMode}>
                      {this.props.intl.formatMessage({
                        id: "EqSellMode." + EqSellMode,
                      })}
                    </option>
                  );
                })}
              </FormSelect>
            </Col>
          </Form.Group>

          {parseInt(this.state.mode) === EqSellModes.LEASING && (
            <Form.Group as={Row} className="mb-3 align-items-center">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Rental.Renewal" })}
                htmlFor="rental_renewal"
                displayAsCol
                required
              />
              <Col md={7}>
                <Form.Group as={Row}>
                  <Col md={3}>
                    <MenuButton
                      className="w-100"
                      onClick={() =>
                        this.onChange(
                          "rental_renewal",
                          this.state.rental_renewal - 1,
                        )
                      }
                    >
                      -
                    </MenuButton>
                  </Col>
                  <Col md={6} className="ps-4 pe-4">
                    <div className="form-control text-center">
                      {this.state.rental_renewal}{" "}
                      <FormattedMessage id="Month" />
                    </div>
                  </Col>
                  <Col md={3}>
                    <MenuButton
                      className="text-center w-100"
                      onClick={() =>
                        this.onChange(
                          "rental_renewal",
                          this.state.rental_renewal + 1,
                        )
                      }
                    >
                      +
                    </MenuButton>
                  </Col>
                </Form.Group>
              </Col>
            </Form.Group>
          )}

          <Form.Group as={Row} className="mb-3 align-items-center">
            <CustomLabel
              label={this.props.intl.formatMessage({
                id: "First.Install.Date",
              })}
              htmlFor="first_install_date"
              displayAsCol
            />
            <Col md={7}>
              <InputTextElement
                type="date"
                id="first_install_date"
                max={DateUtil.DateTime().now().toISODate()}
                value={this.state.first_install_date}
                onChange={(e) =>
                  this.onChange("first_install_date", e.target.value)
                }
                onBlur={(e) =>
                  this.onBlur("first_install_date", e.target.value)
                }
              />
              {/* <div className="text-danger"><small>{this.state.first_install_date_error}</small></div> */}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 align-items-center">
            <CustomLabel
              label={this.props.intl.formatMessage({
                id: "Last.Prescription.Date",
              })}
              htmlFor="last_prescription_date"
              displayAsCol
            />
            <Col md={7}>
              <InputTextElement
                type="date"
                id="last_prescription_date"
                max={DateUtil.DateTime().now().toISODate()}
                value={this.state.last_prescription_date}
                onChange={(e) =>
                  this.onChange("last_prescription_date", e.target.value)
                }
                onBlur={(e) =>
                  this.onBlur("last_prescription_date", e.target.value)
                }
              />
              <div className="text-danger">
                <small>{this.state.last_prescription_date_error}</small>
              </div>
            </Col>
          </Form.Group>
        </Modal.Body>

        {!this.isUpdate && (
          <Modal.Footer>
            <MenuButton variant="secondary" onClick={() => this.props.close()}>
              <FormattedMessage id="Cancel" />
            </MenuButton>
            <MenuButton
              onClick={() => this.onSubmit()}
              disabled={this.disabled()}
            >
              <FormattedMessage id="Add" />
            </MenuButton>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auditEquipments: state.auditDMs,
    auditRooms: state.auditRooms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatientAdmin: (patient, successCallback) =>
      dispatch(updPatientAdmin(patient, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AddPatientEquipmentModal));
