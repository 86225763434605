import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../../util/Util";
import MenuButton from "../bootstrap/MenuButton";
import { Row, Col, Card, ListGroup, Alert, FormSelect } from "react-bootstrap";
import CustomLabel from "../CustomLabel";
import SwitchToggle from "../SwitchToggle";

class EmailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prescribersSelected: this.props.prescriber ? [this.props.prescriber] : [],
      helpersSelected: this.props.helper ? [this.props.helper] : [],
      prescribersNotSelected: this.props.patient.prescribers_ids,
      helpersNotSelected: this.props.patient.helpers_ids,
      send_mail_patient: false,
      send_mail_helpers: this.props.helper ? true : false,
      send_mail_prescribers: this.props.prescriber ? true : false,
      processing: false,
      btnMessage: <FormattedMessage id="Send" />,
    };
  }

  scanForm() {
    if (
      this.state.prescribersSelected.length === 0 &&
      this.state.helpersSelected.length === 0 &&
      !this.state.send_mail_patient
    )
      return true;
  }

  sendEmails() {
    this.setState({ processing: true });
    this.setState({ btnMessage: <FormattedMessage id="Plz.Wait" /> });

    let emailsToSend = [];
    for (let prescriberSelected of this.state.prescribersSelected) {
      if (prescriberSelected && prescriberSelected.email) {
        emailsToSend.push(prescriberSelected.email);
      }
    }
    for (let helperSelected of this.state.helpersSelected) {
      if (helperSelected && helperSelected.email) {
        emailsToSend.push(helperSelected.email);
      }
    }
    if (this.state.send_mail_patient)
      emailsToSend.push(this.props.patient.email);

    this.props.sendEmails(emailsToSend, this.props.audit);
  }

  removeEmail(selected) {
    if (Object.keys(selected)[0] === "prescriber") {
      let prescriber = this.props.prescribers.find(
        (prescriber) => prescriber._id === Object.values(selected)[0],
      );

      if (prescriber && prescriber.email) {
        this.setState((state) => ({
          prescribersSelected: [...this.state.prescribersSelected, prescriber],
        }));
      }

      let prescribersSelected = this.state.prescribersSelected;
      let indexPres = prescribersSelected.findIndex(
        (prescriberSelected) =>
          prescriberSelected._id === Object.values(selected)[0],
      );
      delete prescribersSelected[indexPres];
      let prescribersSelectedFiltered = prescribersSelected.filter(
        function (prescriberSelected) {
          return prescriberSelected != null;
        },
      );

      this.setState((state) => ({
        prescribersSelected: prescribersSelectedFiltered,
      }));
    }

    if (Object.keys(selected)[0] === "helper") {
      let helper = this.props.helpers.find(
        (helper) => helper._id === Object.values(selected)[0],
      );

      if (helper && helper.email) {
        this.setState((state) => ({
          helpersSelected: [...this.state.helpersSelected, helper],
        }));
      }

      let helpersSelected = this.state.helpersSelected;
      let indexHelp = helpersSelected.findIndex(
        (helperSelected) => helperSelected._id === Object.values(selected)[0],
      );
      delete helpersSelected[indexHelp];
      let helpersSelectedFiltered = helpersSelected.filter(
        function (helperSelected) {
          return helperSelected != null;
        },
      );

      this.setState((state) => ({ helpersSelected: helpersSelectedFiltered }));
    }
  }

  pushEmail(userType, domElement) {
    const selectedValue = {
      [userType]: domElement.value ? domElement.value : domElement,
    };

    if (userType === "prescriber") {
      let prescriber = this.props.prescribers.find(
        (prescriber) => prescriber._id === Object.values(selectedValue)[0],
      );

      if (prescriber && prescriber.email) {
        this.setState((state) => ({
          prescribersSelected: [...this.state.prescribersSelected, prescriber],
        }));
      }
    }
    if (userType === "helper") {
      let helper = this.props.helpers.find(
        (helper) => helper._id === Object.values(selectedValue)[0],
      );

      if (helper && helper.email) {
        this.setState((state) => ({
          helpersSelected: [...this.state.helpersSelected, helper],
        }));
      }
    }
    if (userType === "patient") {
      if (this.state.send_mail_patient) {
        this.setState({ send_mail_patient: false });
      } else {
        this.setState({ send_mail_patient: true });
      }
    }

    domElement.value = "";
  }

  notSelectOption(item) {
    if (item === undefined) return null;
    const fullName = Util.formatFullName(item.first_name, item.name);
    return (
      <option
        key={item._id + "-notselected"}
        value={item._id}
        disabled={item.email ? false : true}
      >
        {item.email
          ? fullName
          : `${fullName} (${this.props.intl.formatMessage({ id: "No.Email" }).toLowerCase()})`}
      </option>
    );
  }

  selectedOption(item, userType) {
    if (item === undefined) return null;
    return (
      <ListGroup.Item
        variant="light"
        key={item._id + "-selected"}
        className="d-flex justify-content-between p-2"
      >
        <span>{Util.formatFullName(item.first_name, item.name)}</span>
        <MenuButton
          size="sm"
          icon="circle-xmark"
          variant="outline-danger"
          onClick={() => {
            this.removeEmail({ [userType]: item._id });
          }}
        />
      </ListGroup.Item>
    );
  }

  initNotSelectedNode(itemsNotSelected, itemsSelected) {
    if (!itemsSelected || !itemsNotSelected || itemsNotSelected.length === 0) {
      return null;
    }

    return itemsNotSelected.map((itemNotSelected) => {
      if (
        itemsSelected &&
        itemsSelected.length !== 0 &&
        itemsSelected.find(
          (itemSelected) => itemSelected._id === itemNotSelected,
        )
      )
        return null;
      let itemInfo = this.props.clients.find(
        (item) => item._id === itemNotSelected,
      );
      return this.notSelectOption(itemInfo);
    });
  }

  initSelectedNode(items, userType) {
    if (!items || items.length === 0) return null;
    return items.map((item) => {
      if (!item || item.length === 0) return null;
      return this.selectedOption(item, userType);
    });
  }

  displayNodes(itemsSelectedNode, itemsNotSelectedNode, userType) {
    let formId, messageId, messageSendTo, selectedRecipients;
    if (userType === "helper") {
      formId = "send_mail_helpers";
      messageId = "Select.Helpers";
      messageSendTo = "Send.To.Helper";
      selectedRecipients = "helpersSelected";
    } else {
      formId = "send_mail_prescribers";
      messageId = "Select.Prescribers";
      messageSendTo = "Send.To.Prescriber";
      selectedRecipients = "prescribersSelected";
    }

    const displayList = () => {
      const currentState = this.state[formId];

      this.setState({
        [formId]: !currentState,
      });

      // Reset state if current SwitchToggle not checked
      if (this.state[formId]) {
        this.setState({ [selectedRecipients]: [] });
      }
    };

    return (
      <>
        <Row className="d-flex align-items-center">
          <Col className="text-start">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: messageSendTo })}
              htmlFor={formId}
            />
          </Col>
          <Col className="text-end pe-4">
            <SwitchToggle
              id={formId}
              onChange={() => {
                displayList();
              }}
              checked={this.state[formId]}
              disabled={
                !itemsNotSelectedNode || itemsNotSelectedNode.length === 0
              }
            />
          </Col>
        </Row>

        <Row className={!this.state[formId] && "d-none"}>
          <Col>
            <FormSelect
              onChange={(e) => {
                this.pushEmail(userType, e.target);
              }}
            >
              <option value="">
                {this.props.intl.formatMessage({ id: messageId })}...
              </option>
              {itemsNotSelectedNode}
            </FormSelect>
          </Col>
        </Row>
        <Row className={!this.state[formId] ? "d-none mb-3" : "mb-3"}>
          <Col>
            {itemsSelectedNode && (
              <Card>
                <ListGroup variant="flush">{itemsSelectedNode}</ListGroup>
              </Card>
            )}
          </Col>
        </Row>
      </>
    );
  }

  render() {
    const { patient } = this.props;

    let prescribersNotSelectedNode = this.initNotSelectedNode(
      this.state.prescribersNotSelected,
      this.state.prescribersSelected,
    );

    let prescribersSelectedNode = this.initSelectedNode(
      this.state.prescribersSelected,
      "prescriber",
    );

    let helpersNotSelectedNode = this.initNotSelectedNode(
      this.state.helpersNotSelected,
      this.state.helpersSelected,
    );

    const helpersSelectedNode = this.initSelectedNode(
      this.state.helpersSelected,
      "helper",
    );

    return (
      <div className="mx-auto text-center">
        <Alert variant="warning" className="text-start">
          <FormattedMessage id="Send.Report.Warning" />
        </Alert>
        <Row>
          <Col className="text-start">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Send.To.Patient" })}
              htmlFor="send_mail_patient"
            />
          </Col>
          <Col className="text-end pe-4">
            <SwitchToggle
              id={"send_mail_patient"}
              onChange={(e) => {
                this.pushEmail("patient", patient);
              }}
              checked={this.state.send_mail_patient}
              disabled={Util.emptyString(patient.email)}
            />
          </Col>
        </Row>

        {this.displayNodes(
          prescribersSelectedNode,
          prescribersNotSelectedNode,
          "prescriber",
        )}
        {this.displayNodes(
          helpersSelectedNode,
          helpersNotSelectedNode,
          "helper",
        )}

        <Row className="mt-2">
          <Col>
            <MenuButton
              icon="paper-plane"
              onClick={() => {
                this.sendEmails();
              }}
              disabled={this.scanForm()}
              processing={this.state.processing}
            >
              {this.state.btnMessage}
            </MenuButton>
          </Col>
        </Row>

        {!this.props.origin && (
          <Row>
            <Col className="mt-2 text-center">
              <MenuButton
                variant="link"
                onClick={() => {
                  this.props.close();
                }}
              >
                <FormattedMessage id="End.Without.Send.Mail" />
              </MenuButton>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default injectIntl(EmailPage);
