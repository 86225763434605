import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import APIUrl from "../../../APIUrl";
import { Row, Col, Image, Card, FormSelect } from "react-bootstrap";
import MenuButton from "../../sub/bootstrap/MenuButton";
import PriceUtil from "../../../util/PriceUtil";
import ProductsParserUtil from "../../../util/ProductsParserUtil";
import { connect } from "react-redux";
import MercurialStatus from "../../../enums/MercurialStatus";

class DeskProductRelatedCatalogProductRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      _formatId: this.props.product._id,
    };
  }

  getProduct(product, id) {
    if (product._id === id) return product;

    for (let format of product.formats) {
      if (format._id === id) return format;
    }
  }

  getCatalog(product) {
    return this.props.mercurials.find((m) => product.mercurial_id === m._id);
  }

  changeFormat(e) {
    const formatId = e.target.value.split(";")[0];
    this.setState({ _formatId: formatId });
  }

  render() {
    const { product, productsInState, selectProduct, unselectProduct } =
      this.props;

    const productFormat = this.getProduct(product, this.state._formatId);
    const selectedProduct = productFormat ? productFormat : product;

    const imgSrc = `${APIUrl.getProductImg}${product.mercurial_id}/${encodeURIComponent(selectedProduct.ref_frn)}/0/${encodeURIComponent(selectedProduct.ref)}/${encodeURIComponent(product.parentProductRefFrn)}/?token=${APIUrl.jwtToken}`;
    const priceTtc = PriceUtil.priceTtc(selectedProduct, 1, 2);
    const isProductSelected =
      productsInState &&
      productsInState.length !== 0 &&
      productsInState.find((p) => p._id === productFormat._id);

    let formats = null;
    if (product.formats.length > 1) {
      formats = (
        <FormSelect
          onChange={(e) => this.changeFormat(e)}
          defaultValue={product._id}
        >
          {product.formats.map((format) => (
            <option
              key={format._id}
              value={format._id + ";" + format.format.trim()}
            >
              {format.format.trim()}
            </option>
          ))}
        </FormSelect>
      );
    }

    const catalog = this.getCatalog(product);

    const now = new Date();
    const hasExpired =
      catalog.end_date &&
      (new Date(catalog.start_date) > now || new Date(catalog.end_date) < now);

    if (hasExpired || catalog.status === MercurialStatus.INACTIVE) return null;

    const catalogName = catalog ? catalog.name : "-";

    return (
      <Card
        key={product._id}
        bg={!isProductSelected && "light"}
        className={isProductSelected ? "bg-success-selected mb-1" : "mb-1"}
      >
        <Card.Body>
          <Row className="d-flex align-items-center">
            <Col sm={2} className="text-center">
              <Image
                src={imgSrc}
                alt={product._id}
                style={{ maxWidth: "8em" }}
                className={
                  isProductSelected
                    ? "border border-success img-thumbnail"
                    : "img-thumbnail"
                }
                thumbnail
                onError={(e) =>
                  (e.target.src = `${APIUrl.rootUrl}/img/no_image_512.png`)
                }
              />
            </Col>
            <Col>
              <Row>
                <Col>
                  <strong>
                    <FormattedMessage id="Catalog" />
                  </strong>{" "}
                  : {catalogName}
                </Col>
              </Row>
              <Row>
                <Col>
                  <strong>
                    <FormattedMessage id="Product" />
                  </strong>{" "}
                  : {ProductsParserUtil.getDesignation(product)}
                </Col>
              </Row>
              <Row>
                <Col>
                  <strong>
                    <FormattedMessage id="Reference" />
                  </strong>{" "}
                  : {selectedProduct.ref}
                </Col>
              </Row>
              <Row>
                <Col>
                  <strong>
                    <FormattedMessage id="Unit.Price.Incl.Tax" />
                  </strong>{" "}
                  : {PriceUtil.formatEuro(priceTtc)}
                </Col>
              </Row>
              <Row>
                <Col>
                  <strong>
                    <FormattedMessage id="Lpp.Amount" />
                  </strong>{" "}
                  :{" "}
                  {selectedProduct.lpp_amount
                    ? PriceUtil.formatEuro(selectedProduct.lpp_amount)
                    : "-"}
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col className="text-center">{formats}</Col>
              </Row>
            </Col>
            <Col sm={2}>
              <Row>
                <Col className="text-end">
                  <MenuButton
                    icon={!isProductSelected ? "cart-plus" : "check"}
                    className={isProductSelected && "border border-white"}
                    size="sm"
                    variant={
                      !isProductSelected ? "outline-secondary" : "success"
                    }
                    onClick={() => {
                      !isProductSelected
                        ? selectProduct(selectedProduct)
                        : unselectProduct(selectedProduct);
                    }}
                  >
                    <FormattedMessage
                      id={!isProductSelected ? "Select" : "Product.Selected"}
                    />
                  </MenuButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mercurials: state.mercurials,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DeskProductRelatedCatalogProductRow));
