import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Row, Col, Alert, Modal } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import ProductsParserUtil from "../../../util/ProductsParserUtil";
import DeskProductRelatedCatalogProductRow from "./DeskProductRelatedCatalogProductRow";
import MenuButton from "../../sub/bootstrap/MenuButton";

class DeskProductRelatedCatalogProducts extends React.Component {
  constructor(props) {
    super(props);

    this.initialSelectedProducts = this.props.deskCart.filter(
      (p) => p.deskStoreProduct._id === this.props.deskStoreProduct._id,
    );

    this.state = {
      selectedProduct: null,
      selectedProducts: this.initialSelectedProducts,
      productsToDelete: [],
    };
  }

  selectProduct(product) {
    this.setState({
      selectedProducts: [...this.state.selectedProducts, product],
      productsToDelete: this.state.productsToDelete.filter(
        (p) => p._id !== product._id,
      ),
    });
  }

  unselectProduct(product) {
    this.setState(
      {
        selectedProducts: this.state.selectedProducts.filter(
          (p) => p._id !== product._id,
        ),
      },
      () => {
        if (this.props.deskCart.find((p) => p._id === product._id)) {
          this.setState({
            productsToDelete: [...this.state.productsToDelete, product],
          });
        }
      },
    );
  }

  swapProduct(oldProduct, newProduct) {
    const updatedSelectedProducts = [
      ...this.state.selectedProducts,
      newProduct,
    ].filter((p) => p._id !== oldProduct._id);
    this.setState({
      selectedProduct: newProduct,
      selectedProducts: updatedSelectedProducts,
      productsToDelete: [...this.state.productsToDelete, oldProduct],
    });
  }

  addToCart(callback) {
    if (this.state.selectedProducts.length === 0) return;

    const productsToAdd = [];

    for (let i = 0; i < this.state.selectedProducts.length; i++) {
      if (
        this.props.deskCart.find(
          (p) => p._id === this.state.selectedProducts[i]._id,
        )
      )
        continue;

      let product = this.state.selectedProducts[i];
      product.quantity = 1;
      product.deskStoreProduct = this.props.deskStoreProduct;
      productsToAdd.push(product);
    }

    this.props.addToCart(productsToAdd, callback);
  }

  deleteFromCart(callback) {
    if (this.state.productsToDelete.length === 0) return;

    this.props.deleteFromCart(this.state.productsToDelete, callback);
  }

  onConfirm() {
    const callback = () => {
      if (
        this.state.productsToDelete &&
        this.state.productsToDelete.length !== 0
      ) {
        this.deleteFromCart(this.props.closeModal());
      }
      NotificationManager.success(
        <FormattedMessage id="Cart.Updated" />,
        <FormattedMessage id="Message" />,
        6000,
      );
      this.props.closeModal();
    };

    if (
      this.state.selectedProducts &&
      this.state.selectedProducts.length !== 0
    ) {
      this.addToCart(callback);
    }
  }

  render() {
    const { deskStoreProduct, products, closeModal } = this.props;

    const productsFromCatalog = products.filter((p) =>
      deskStoreProduct.productsFromCatalog.find(
        (pFromCatalog) => pFromCatalog.product_id === p._id,
      ),
    );

    for (let p of productsFromCatalog) {
      p.sortIndex = deskStoreProduct.productsFromCatalog.find(
        (_p) => _p.product_id === p._id,
      ).sortIndex;
    }

    const productsFromCatalogIds = deskStoreProduct.productsFromCatalog.map(
      (p) => p.product_id,
    );

    const formatedProducts = ProductsParserUtil.parseProducts(
      productsFromCatalog,
      productsFromCatalogIds,
    );

    const productsFromCatalogNodes = formatedProducts
      .sort((a, b) => a.sortIndex - b.sortIndex)
      .map((product) => (
        <DeskProductRelatedCatalogProductRow
          key={product._id}
          product={product}
          productsInState={this.state.selectedProducts}
          selectProduct={(product) =>
            this.props.productToSwap && !this.state.selectedProduct
              ? this.swapProduct(this.props.productToSwap, product)
              : this.selectProduct(product)
          }
          unselectProduct={(product) => this.unselectProduct(product)}
        />
      ));

    return (
      <Modal
        show={true}
        onHide={() => closeModal()}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Products.From.Catalog" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              {deskStoreProduct.productsFromCatalog.length > 0 ? (
                productsFromCatalogNodes
              ) : (
                <Alert variant="secondary">
                  <FormattedMessage id="Products.None" />
                </Alert>
              )}
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <MenuButton variant="secondary" onClick={() => closeModal()}>
            <FormattedMessage id="Cancel" />
          </MenuButton>
          <MenuButton
            onClick={() => this.onConfirm()}
            disabled={
              (!this.state.selectedProducts ||
                this.state.selectedProducts.length === 0 ||
                this.state.selectedProducts === this.initialSelectedProducts) &&
              (!this.state.productsToDelete ||
                this.state.productsToDelete.length === 0)
            }
          >
            <FormattedMessage id="Confirm" />
          </MenuButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default injectIntl(DeskProductRelatedCatalogProducts);
