import React from "react";
import { connect } from "react-redux";
import APIUrl from "../../../APIUrl";
import StringUtil from "../../../util/StringUtil";
import { FormattedMessage } from "react-intl";
import Util from "../../../util/Util";

class DeskTemplateCerfaPDF extends React.Component {
  render() {
    const { id, patient, deskStore, auditSettings } = this.props;

    // ELEMENTS / PRODUCTS TO RENDER

    let vtelocNodes = "";
    let vteloc_description_text_header = (
      <FormattedMessage
        id="Health.Message"
        values={{
          name: Util.formatFullName(patient.first_name, patient.name),
        }}
      />
    );

    if (deskStore && deskStore.length > 0) {
      vtelocNodes = deskStore.map((product, idx) => {
        let vteloc_description_text = "";

        if (product.vte_loc) {
          switch (product.vte_loc) {
            case "Vente":
              vteloc_description_text = product.prescription_type_vte_text;
              break;
            case "Location":
              vteloc_description_text = product.prescription_type_loc_text;
              break;
            default:
              break;
          }
        }

        if (vteloc_description_text !== "") {
          return (
            <li key={"pdfvte_loc" + idx}>
              {StringUtil.ucFirst(vteloc_description_text)}
            </li>
          );
        } else {
          return null;
        }
      });
    }

    // RENDER PDF TEMPLATE
    return (
      <div
        id={id}
        style={{
          width: "100%",
          height: "100%",
          marginLeft: "0",
          marginRight: "0",
          marginTop: "0",
          marginBottom: "0",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            marginLeft: "0",
            marginRight: "0",
            marginTop: "0",
            marginBottom: "0",
          }}
        >
          {auditSettings.enable_cerfa_specimen && (
            <div
              className="imgcerfa"
              style={{
                zIndex: 0,
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                marginLeft: "0",
                marginRight: "0",
                marginTop: "0",
                marginBottom: "0",
              }}
            >
              <img
                style={{ width: "100%", height: "100%" }}
                src={APIUrl.rootUrl + "/img/desk_cerfa_1.jpg"}
                alt="Cerfa_.5786838278039104"
              />
            </div>
          )}

          <div
            className="block__patient"
            style={{
              zIndex: 5,
              borderWidth: "1",
              position: "absolute",
              width: "100%",
              top: "205px",
              left: "0",
              textAlign: "center",
              marginLeft: "0",
              marginRight: "0",
              marginTop: "0",
              marginBottom: "0",
            }}
          >
            {Util.formatFullName(patient.first_name, patient.name)}
          </div>
          <div
            className="block__vteloc"
            style={{
              zIndex: 10,
              borderWidth: "1",
              position: "absolute",
              width: "88%",
              top: "330px",
              left: "5%",
              marginLeft: "0",
              marginRight: "0",
              marginTop: "0",
              marginBottom: "0",
            }}
          >
            {vtelocNodes !== "" ? (
              <div key={"pdfvte_loc"} style={{ marginBottom: "1em" }}>
                {vteloc_description_text_header}
                <ol>{vtelocNodes}</ol>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deskProducts: state.deskProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeskTemplateCerfaPDF);
