import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import PatientModal from "./PatientModal";
import Paginator from "../sub/Paginator";
import PatientRow from "./PatientRow";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import {
  deletePatient,
  importPatients,
  getPatientEquipmentsReminded,
} from "../../actions/patients/patients";
import Roles from "../../enums/Roles";
import Util from "../../util/Util";
import {
  Alert,
  Badge,
  Col,
  Form,
  FormSelect,
  Modal,
  Row,
} from "react-bootstrap";
import ImportExcelFileColumnsModal from "../sub/modals/ImportExcelFileColumnsModal";
import ImportExcelFileModal from "../sub/modals/ImportExcelFileModal";
import ExcelUtil from "../../util/ExcelUtil";
import { NotificationManager } from "react-notifications";
import MenuButton from "../sub/bootstrap/MenuButton";
import PatientsEquipmentsReminded from "./PatientsEquipmentsReminded.js";
import InputLength from "../../enums/InputLength";
import InputTextElement from "../sub/InputTextElement.js";
import TableToolbar from "../sub/bootstrap/TableToolbar.js";
import APIUrl from "../../APIUrl";
import PatientMapping from "../../util/PatientMapping";
import ObjectUtil from "../../util/ObjectUtil";
import DateUtil from "../../util/DateUtil";
import { checkIfEmailIsValid } from "../../actions/user/user";
import ActionMenu from "../sub/ActionMenu";
import SwitchToggle from "../sub/SwitchToggle";
import CustomLabel from "../sub/CustomLabel";
import Icon from "../sub/Icon";

class Patients extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,

      // Search filters
      nameFilter: "",
      addressFilter: "",
      patientIsActiveFilter: "active",
      auditRequestedFilter: false,
      patientAccessFilter: "",
      remindedEquipments: [],
      isReminderModal: false,
    };

    this.paginator = new Paginator(this);
  }

  componentDidMount() {
    if (
      this.props.user.role === Roles.ADMIN ||
      this.props.user.role === Roles.SALES_REP
    ) {
      this.props.onGetPatientEquipmentsReminded((data) => {
        this.setState({ remindedEquipments: data });
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.user.role === Roles.ADMIN ||
      this.props.user.role === Roles.SALES_REP
    ) {
      if (prevProps.patients !== this.props.patients) {
        const callback = () => {
          if (this.state.modal !== null && this.state.isReminderModal)
            this.openRemindedEquipmentsModal(this.state.remindedEquipments);
        };
        this.props.onGetPatientEquipmentsReminded((data) => {
          this.setState({ remindedEquipments: data }, callback);
        });
      }
    }
  }

  openModal(patient, target) {
    this.setState({ modal: null }, () =>
      this.setState({
        modal: (
          <PatientModal
            isOpen={true}
            patientId={patient ? patient._id : null}
            openModal={(patient, target) => this.openModal(patient, target)}
            close={() => this.closeModal()}
            target={target}
          />
        ),
      }),
    );
  }

  openConfModal(
    title,
    content,
    successCallback,
    mandatoryConfirmation = false,
  ) {
    this.setState({
      modal: (
        <ConfirmationModal
          title={title}
          size="lg"
          variant="warning"
          onAccept={successCallback}
          onDecline={() => this.closeModal()}
          mandatoryConfirmation={mandatoryConfirmation}
        >
          {content}
        </ConfirmationModal>
      ),
    });
  }

  delete(patient) {
    if (!patient) return;

    const title = <FormattedMessage id="Patient.Delete.title" />;

    const content = (
      <>
        <Alert variant="warning">
          <h5>
            <Icon icon="triangle-exclamation" className="me-2" />
            <FormattedMessage id="Warning" />
          </h5>
          <p>
            <FormattedMessage id="Patient.Delete.Content.1" />
          </p>
          <p>
            <FormattedMessage id="Patient.Delete.Content.2" />
          </p>
        </Alert>

        <p>
          <FormattedMessage id="Confirm.Patient.Removal" />
        </p>
      </>
    );

    this.openConfModal(
      title,
      content,
      () => this.props.onDeletePatient(patient._id, () => this.closeModal()),
      true,
    );
  }

  closeModal() {
    this.setState({ modal: null });
  }

  areResultsFiltered = () => {
    if (
      !Util.emptyString(this.state.nameFilter) ||
      !Util.emptyString(this.state.addressFilter) ||
      this.state.patientIsActiveFilter !== "all" ||
      this.state.auditRequestedFilter !== false ||
      this.state.patientAccessFilter !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  resetSearchFields() {
    this.setState({
      nameFilter: "",
      addressFilter: "",
      patientIsActiveFilter: "all",
      auditRequestedFilter: false,
      patientAccessFilter: "",
    });
  }

  // Reset all previous import params in state when aborting import process
  abortAndCloseModal() {
    this.setState({
      fileName: null,
      fileData: null,
    });

    this.closeModal();
  }

  /**
   * Performs checks upon data.
   * We try to avoid sending data that will be rejected by the backend (because of Mongo/Mongoose field types for example)
   *
   * @param {*} columnsReferenceList
   */
  async checkDataIntegrity(fileData, columnsReferenceList, maxErrorToDisplay) {
    let errorsFound = [];
    const itemMapping = PatientMapping;
    var columns = {};

    // Store association between required column and matching column in the file (labels can be different if we didnt used automatching or partial automatching)
    for (let key of Object.keys(itemMapping)) {
      columns[key] = itemMapping[key];
    }

    let currentError;

    // Default maxlength for a value (if checked)
    // May be locally changed on some values
    let maxlength;

    // Add an error to the stack
    const addError = (error) => {
      if (Util.typeOf(error) === "Object") {
        errorsFound.push(error);
      }
    };

    // Loop through file rows
    for (let row of fileData) {
      if (errorsFound.length === maxErrorToDisplay) {
        break;
      }

      // Get current row keys
      let currentRowKeys = Object.keys(row);

      /**
       * CHECK SPECIAL FIELDS (where we know that value must be an integer or a float for example)
       */
      for (let key of currentRowKeys) {
        if (errorsFound.length === maxErrorToDisplay) {
          break;
        }

        let itemMappingReferenceKey = ObjectUtil.getKeyByValue(
          columnsReferenceList,
          key,
        );

        if (
          (itemMappingReferenceKey === "name" && Util.emptyString(row[key])) ||
          (itemMappingReferenceKey === "first_name" &&
            Util.emptyString(row[key])) ||
          (itemMappingReferenceKey === "address" &&
            Util.emptyString(row[key])) ||
          (itemMappingReferenceKey === "postal_code" &&
            Util.emptyString(row[key])) ||
          (itemMappingReferenceKey === "city" && Util.emptyString(row[key]))
        ) {
          currentError = {
            numRow: row.__rowNum__ + 1,
            field: key,
            targetField: itemMapping[itemMappingReferenceKey],
            value: row[key],
            hint: <FormattedMessage id="Field.Cant.Be.Empty" />,
          };

          addError(currentError);
          break;
        }

        switch (true) {
          // Perform tests on date fields
          case itemMappingReferenceKey === "birth_date":
            // We allow those fields to be bypassed.
            // To allow this, we must provide a dash caracter ("-") in the selected field
            if (!Util.emptyString(row[key])) {
              let dateVerified = DateUtil.ExcelDateCheck(row[key]);

              if (dateVerified === "Invalid DateTime") {
                currentError = {
                  numRow: row.__rowNum__ + 1,
                  field: key,
                  targetField: itemMapping[itemMappingReferenceKey],
                  value: row[key],
                  hint: (
                    <FormattedMessage
                      id="Value.Is.Not.Valid.Date"
                      values={{ value: row[key] }}
                    />
                  ),
                };
                addError(currentError);
              }
            }
            break;

          // Perform tests on postal code field
          case itemMappingReferenceKey === "postal_code":
            // We allow those fields to be bypassed.
            // To allow this, we must provide a dash caracter ("-") in the selected field
            if (!Util.isPostal(row[key])) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Valid.Postal.Code"
                    values={{ value: row[key] }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          // Perform tests on phone fields
          case itemMappingReferenceKey === "phone":
          case itemMappingReferenceKey === "mobile":
            // We allow those fields to be bypassed.
            // To allow this, we must provide a dash caracter ("-") in the selected field
            if (!Util.emptyString(row[key]) && !Util.isPhone(row[key])) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Valid.Phone.Number"
                    values={{ value: row[key] }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          // Define default value for those fields (might be required later in email check for instance)
          case itemMappingReferenceKey === "accessPHARMA":
          case itemMappingReferenceKey === "notifyByEmail":
            if (Util.emptyString(row[key])) {
              row[itemMapping[itemMappingReferenceKey]] = "Non";
            }
            break;

          // Perform tests on email field
          case itemMappingReferenceKey === "email":
            maxlength = InputLength.EMAIL;

            if (
              Util.emptyString(row[key]) &&
              row[itemMapping["accessPHARMA"]] === "Oui"
            ) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Error.Account.Email.Required"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            }

            if (!Util.emptyString(row[key])) {
              this.props.onCheckIfEmailIsValid(row[key], null, (response) => {
                let currentError = {
                  numRow: row.__rowNum__ + 1,
                  field: key,
                  targetField: itemMapping[itemMappingReferenceKey],
                  value: row[key],
                  hint: (
                    <span>
                      <FormattedMessage id="Invalid.Email.Error" /> (
                      {response.data.reason})
                    </span>
                  ),
                };
                addError(currentError);
              });
            }

            if (row[key].length > maxlength) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Error.Maxlength"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            } else if (!Util.emptyString(row[key]) && !Util.isEmail(row[key])) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Valid.Email.address"
                    values={{ value: row[key] }}
                  />
                ),
              };
              addError(currentError);
            }

            break;

          case itemMappingReferenceKey === "name":
          case itemMappingReferenceKey === "first_name":
            maxlength = InputLength.NAME;
            // Check maxlength
            if (!Util.safeGenericString(row[key], 1, maxlength)) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Valid.Data"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          case itemMappingReferenceKey === "address":
          case itemMappingReferenceKey === "addressAdditionnal":
          case itemMappingReferenceKey === "city":
            maxlength = InputLength.ADDRESS;
            // Check maxlength
            if (
              !Util.emptyString(row[key]) &&
              !Util.safeGenericString(row[key], 1, maxlength)
            ) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Valid.Data"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          case itemMappingReferenceKey === "codeCRM":
            maxlength = InputLength.ID;
            // Check maxlength
            if (
              !Util.emptyString(row[key]) &&
              !Util.safeGenericString(row[key], 1, maxlength)
            ) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Valid.Data"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          default:
            // Convert field to string to evaluate it
            let fieldValue = row[key].toString();
            // Double check string fields that may contain only spaces (so they are not considered as empty)
            // We trim the value in order to catch'em as well eventually
            if (fieldValue.trim() === "") {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: itemMapping[itemMappingReferenceKey],
                value: "",
                hint: <FormattedMessage id="Field.Cant.Be.Empty" />,
              };

              addError(currentError);
            }
            break;
        }
      }
    }

    return errorsFound;
  }

  // Step 1 : Import excel file
  initStep1(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      mode: "create",
      modal: (
        <ImportExcelFileModal
          title={<FormattedMessage id="Import.Patients" />}
          templateFileUrl={APIUrl.getPatientsTemplate}
          templateFileName={"template_patients"}
          closeModal={() => this.abortAndCloseModal()}
          onComplete={(fileData) => this.initStep2(fileData)}
        />
      ),
    });
  }

  // Step 2 : File column mapping and data validation
  initStep2(file) {
    // Parse the excel file and then move on to next modal
    ExcelUtil.parse(file, (fileData) => {
      // Save the passed params
      this.setState({
        fileName: file.name,
        fileData: fileData,
      });

      // Close current modal
      this.closeModal();

      // Open next step modal
      this.setState({
        modal: (
          <ImportExcelFileColumnsModal
            closeModal={() => this.abortAndCloseModal()}
            fileData={fileData}
            onComplete={(columns) => this.initStep3(columns)}
            title={<FormattedMessage id="Import.Patients" />}
            itemMapping={PatientMapping}
            checkDataIntegrity={(columnsReferenceList, maxErrorToDisplay) =>
              this.checkDataIntegrity(
                fileData,
                columnsReferenceList,
                maxErrorToDisplay,
              )
            }
          />
        ),
      });
    });
  }

  // Step 3 : Send data to the backend
  initStep3(columns) {
    this.setState({
      columns: columns,
    });

    let successCallback;

    successCallback = () => {
      // Close current modal
      this.closeModal();

      // Notification
      let errorMessage = this.props.intl.formatMessage({
        id: "Import.Successful",
      });

      NotificationManager.success(errorMessage);
    };

    // Send the patients to the BE

    // First, fix data with correct columns
    const patients = this.fixPatientsCols(this.state.fileData, columns);

    this.props.onImportPatients(
      {
        fileName: this.state.fileName,
        patients: patients,
      },
      successCallback,
    );
  }

  // Perform checks on columns <-> data associations for patients
  fixPatientsCols(patients, columns) {
    let newPatients = [];
    for (let e of patients) {
      var newPatient = {};
      for (let col of Object.keys(columns)) newPatient[col] = e[columns[col]];
      newPatients.push(newPatient);
    }

    return newPatients;
  }

  getClientPatients(patient) {
    if (this.props.clientType === "prescriber")
      return patient.prescribers_ids.includes(this.props.clientId);
    if (this.props.clientType === "helper")
      return patient.helpers_ids.includes(this.props.clientId);
    if (this.props.clientType === "patient")
      return patient.user_id.includes(this.props.clientId);
  }

  openRemindedEquipmentsModal(remindedEquipments) {
    const closeModal = () => {
      this.closeModal();
      this.setState({ isReminderModal: false });
    };

    this.setState({
      isReminderModal: true,
      modal: (
        <Modal
          show={true}
          onHide={() => closeModal()}
          backdrop="static"
          keyboard={false}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Alerts.Renewal" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <PatientsEquipmentsReminded equipments={remindedEquipments} />
          </Modal.Body>
        </Modal>
      ),
    });
  }

  renderActionMenu = () => {
    // Hide from client role
    if (this.props.clientId) return;

    let menuAction;
    let menuItems = [];

    menuItems.push({
      icon: "file-circle-plus",
      action: () => this.openModal(),
      text: <FormattedMessage id="Patient.Add" />,
    });

    menuItems.push({
      icon: "upload",
      action: (e) => this.initStep1(e),
      text: <FormattedMessage id="Import.Patients" />,
    });

    menuAction = (
      <ActionMenu
        items={menuItems}
        icon="circle-plus"
        className="ms-1"
        size="2xl"
        label={<FormattedMessage id="Actions" />}
      />
    );

    return menuAction;
  };

  render() {
    let patients = this.props.clientId
      ? this.props.patients.filter((patient) => this.getClientPatients(patient))
      : this.props.patients;

    if (this.props.isAssignModal)
      patients = this.props.patients.filter(
        (patient) =>
          !patient.helpers_ids.includes(this.props.clientId) &&
          !patient.prescribers_ids.includes(this.props.clientId) &&
          !patient.user_id.includes(this.props.clientId),
      );

    // No patients
    if (!patients || patients.length === 0) {
      return (
        <TableToolbar
          message={
            <FormattedMessage
              id={
                this.props.user.role !== Roles.CLIENT
                  ? "Empty.Patient"
                  : "Empty.Patient.Prescriber"
              }
            />
          }
        >
          {!this.props.limit &&
            this.props.user.role !== Roles.CLIENT &&
            !this.props.isAssignModal &&
            this.renderActionMenu()}

          {this.state.modal}
        </TableToolbar>
      );
    }

    this.paginator.init(patients.length);

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    var i = 0;
    let patientsNode = patients.map((patient) => {
      if (this.state.nameFilter && this.state.nameFilter !== "") {
        if (
          patient.name
            .toUpperCase()
            .indexOf(this.state.nameFilter.toUpperCase()) === -1 &&
          patient.first_name
            .toUpperCase()
            .indexOf(this.state.nameFilter.toUpperCase()) === -1
        )
          return null;
      }

      if (this.state.addressFilter && this.state.addressFilter !== "") {
        if (
          patient.address
            .toUpperCase()
            .indexOf(this.state.addressFilter.toUpperCase()) === -1 &&
          patient.postal_code
            .toString()
            .toUpperCase()
            .indexOf(this.state.addressFilter.toUpperCase()) === -1 &&
          patient.city
            .toUpperCase()
            .indexOf(this.state.addressFilter.toUpperCase()) === -1
        )
          return null;
      }

      let displayPatientIsActiveFilter;
      switch (true) {
        case this.state.patientIsActiveFilter === "active" && !patient.active:
        case this.state.patientIsActiveFilter === "inactive" && patient.active:
          displayPatientIsActiveFilter = true;
          break;
        default:
          displayPatientIsActiveFilter = false;
          break;
      }

      const auditRequested =
        Util.typeOf(patient.new_audit_request) !== "Undefined" &&
        patient.new_audit_request.hasOwnProperty("status")
          ? patient.new_audit_request.status
          : false;
      const auditRequestedFilter =
        this.state.auditRequestedFilter !== false &&
        this.state.auditRequestedFilter !== auditRequested;

      if (this.state.patientAccessFilter !== "") {
        if (this.state.patientAccessFilter && patient.user_id.length === 0)
          return null;
        if (!this.state.patientAccessFilter && patient.user_id.length > 0)
          return null;
      }

      if (displayPatientIsActiveFilter || auditRequestedFilter) {
        return null;
      }

      if (this.props.limit && ++i > this.props.limit) return null;

      if (!this.paginator.keep()) return null;

      return (
        <PatientRow
          key={patient._id}
          patient={patient}
          edit={(patient) => this.openModal(patient, "general")}
          delete={(patient) => this.delete(patient)}
          displaysEquipmentModal={(patient) =>
            this.openModal(patient, "equipments")
          }
          displaysPrescribersModal={(patient) =>
            this.openModal(patient, "prescribers")
          }
          displaysHelpersModal={(patient) => this.openModal(patient, "helpers")}
          openConfModal={(title, content, successCallback) =>
            this.openConfModal(title, content, successCallback)
          }
          audits={this.props.audits}
          clients={this.props.clients}
          limit={this.props.limit && true}
          isAssignModal={this.props.isAssignModal}
          addClient={(clientId) => this.props.addClient(clientId)}
          limitInfoAssociatedPatients={this.props.limitInfoAssociatedPatients}
        />
      );
    });

    return (
      <React.Fragment>
        {/** BEGIN SEARCH FILTERS */}
        {!this.props.limit && this.props.user.client_type !== "patient" && (
          <TableToolbar>
            <Form.Group as={Row} className="align-items-center">
              <Col>
                <InputTextElement
                  id="search_name"
                  type="text"
                  className={this.state.nameFilter && "filtered-result"}
                  placeholder={
                    this.props.intl.formatMessage({ id: "Name" }) +
                    " " +
                    this.props.intl.formatMessage({ id: "Or" }) +
                    " " +
                    this.props.intl.formatMessage({ id: "First.Name" })
                  }
                  onChange={(e) => {
                    this.setState({ nameFilter: e.target.value });
                  }}
                  disabled={disableFormInput}
                  value={this.state.nameFilter}
                />
              </Col>
              {!this.props.limitInfoAssociatedPatients && (
                <Col>
                  <InputTextElement
                    id="search_address"
                    type="text"
                    className={this.state.addressFilter && "filtered-result"}
                    placeholder={this.props.intl.formatMessage({
                      id: "Address",
                    })}
                    onChange={(e) => {
                      this.setState({ addressFilter: e.target.value });
                    }}
                    disabled={disableFormInput}
                    value={this.state.addressFilter}
                  />
                </Col>
              )}

              <Col>
                <FormSelect
                  id="patient-status-filter"
                  className={
                    this.state.patientIsActiveFilter !== "all" &&
                    "filtered-result"
                  }
                  value={this.state.patientIsActiveFilter}
                  onChange={(e) =>
                    this.setState({ patientIsActiveFilter: e.target.value })
                  }
                  disabled={disableFormInput}
                >
                  <option value={"all"}>
                    <>
                      {this.props.intl.formatMessage({ id: "Status" })}
                      {": "}
                      {this.props.intl.formatMessage({ id: "All" })}
                    </>
                  </option>
                  <option value={"active"}>
                    <>
                      {this.props.intl.formatMessage({ id: "Status" })}
                      {": "}
                      {this.props.intl.formatMessage({ id: "Active" })}
                    </>
                  </option>
                  <option value={"inactive"}>
                    <>
                      {this.props.intl.formatMessage({ id: "Status" })}
                      {": "}
                      {this.props.intl.formatMessage({ id: "Inactive" })}
                    </>
                  </option>
                </FormSelect>
              </Col>
              {!this.props.limitInfoAssociatedPatients && (
                <>
                  <Col className="col-auto mb-0 text-end text-nowrap pe-0">
                    <CustomLabel
                      label={<FormattedMessage id="Audit.Requested" />}
                    />
                  </Col>
                  <Col className="col-auto pt-1">
                    <SwitchToggle
                      id="patient-new-audit-request-filter"
                      checked={this.state.auditRequestedFilter}
                      onChange={(e) =>
                        this.setState({
                          auditRequestedFilter: e,
                        })
                      }
                      disabled={disableFormInput}
                    />
                  </Col>
                </>
              )}

              <Col>
                <FormSelect
                  id="patient-access-filter"
                  className={
                    this.state.patientAccessFilter && "filtered-result"
                  }
                  value={this.state.patientAccessFilter}
                  onChange={(e) =>
                    this.setState({
                      patientAccessFilter:
                        e.target.value === ""
                          ? e.target.value
                          : JSON.parse(e.target.value),
                    })
                  }
                  disabled={disableFormInput}
                >
                  <option value={""}>
                    {this.props.intl.formatMessage({ id: "Access.PHARMAMAD" })}
                  </option>
                  <option value={true}>
                    {this.props.intl.formatMessage({ id: "Yes" })}
                  </option>
                  <option value={false}>
                    {this.props.intl.formatMessage({ id: "No" })}
                  </option>
                </FormSelect>
              </Col>

              <Col className="col-auto">
                <MenuButton
                  onClick={() => this.resetSearchFields()}
                  hover={
                    this.areResultsFiltered() &&
                    !disableFormInput && <FormattedMessage id="Remove.Filter" />
                  }
                  variant={
                    this.areResultsFiltered() ? "info" : "outline-secondary"
                  }
                  icon="filter"
                  disabled={!this.areResultsFiltered() || disableFormInput}
                />
              </Col>
              {this.props.user.role !== Roles.CLIENT &&
                !this.props.prescriberId &&
                !this.props.isAssignModal &&
                this.props.clientType !== "patient" && (
                  <>
                    {!this.props.limitInfoAssociatedPatients &&
                      this.state.remindedEquipments &&
                      this.state.remindedEquipments.length > 0 && (
                        <Col className="col-auto">
                          <MenuButton
                            icon="bell"
                            variant="warning"
                            onClick={() =>
                              this.openRemindedEquipmentsModal(
                                this.state.remindedEquipments,
                              )
                            }
                          >
                            <Badge
                              bg="danger"
                              pill
                              className="border border-white shadow-sm"
                            >
                              {this.state.remindedEquipments.length}
                            </Badge>
                          </MenuButton>
                        </Col>
                      )}
                    <Col className="col-auto text-end">
                      {this.renderActionMenu()}
                    </Col>
                  </>
                )}
            </Form.Group>
          </TableToolbar>
        )}
        {/** END SEARCH FILTERS */}

        <table
          className={
            this.props.limit
              ? "table table-striped tablee4mad pointer-events-none"
              : "table table-striped tablee4mad"
          }
        >
          <thead>
            <tr>
              {(this.props.user.role === Roles.ADMIN ||
                this.props.user.role === Roles.SALES_REP) && (
                <React.Fragment>
                  <th scope="col" className="col">
                    <FormattedMessage id="Name" />
                  </th>
                  {!this.props.limitInfoAssociatedPatients && (
                    <th scope="col" className="col">
                      <FormattedMessage id="Address" />
                    </th>
                  )}
                  <th scope="col" className="col">
                    <FormattedMessage
                      id="Last.Audit.Date"
                      values={{ er: <sup>er</sup> }}
                    />
                  </th>
                  {!this.props.limit &&
                    !this.props.limitInfoAssociatedPatients && (
                      <th scope="col" className="text-center">
                        <FormattedMessage id="Prescribers" />
                      </th>
                    )}
                  {!this.props.limit &&
                    !this.props.limitInfoAssociatedPatients && (
                      <th scope="col" className="text-center">
                        <FormattedMessage id="Helpers" />
                      </th>
                    )}
                  <th scope="col" className="col-1 text-center">
                    <FormattedMessage id="Access.PHARMAMAD" />
                  </th>
                  {!this.props.limit && (
                    <th scope="col" className="col-1 text-center">
                      <FormattedMessage id="Actions" />
                    </th>
                  )}
                </React.Fragment>
              )}
              {this.props.user.role === Roles.CLIENT && (
                <React.Fragment>
                  <th scope="col" className="col">
                    <FormattedMessage id="Name" />
                  </th>
                  <th scope="col" className="col">
                    <FormattedMessage id="Address" />
                  </th>
                  <th scope="col" className="col">
                    <FormattedMessage
                      id="Last.Audit.Date"
                      values={{ er: <sup>er</sup> }}
                    />
                  </th>
                  <th scope="col" className="col-2 text-center">
                    <FormattedMessage id="Equipments" />
                  </th>
                  <th scope="col" className="col-2 text-center">
                    <FormattedMessage id="Alert.Renewal" />
                  </th>
                  {!this.props.limit && (
                    <th scope="col" className="col-1 text-center">
                      <FormattedMessage id="Actions" />
                    </th>
                  )}
                </React.Fragment>
              )}
            </tr>
          </thead>
          <tbody>{patientsNode}</tbody>
        </table>

        {!this.props.limit && this.paginator.render()}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patients: state.patients,
    user: state.user,
    audits: state.audits,
    clients: state.clients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeletePatient: (patientId, successCallback) =>
      dispatch(deletePatient(patientId, successCallback)),
    onImportPatients: (data, successCallback, failureCallback) =>
      dispatch(importPatients(data, successCallback, failureCallback)),
    onGetPatientEquipmentsReminded: (successCallback) =>
      dispatch(getPatientEquipmentsReminded(successCallback)),
    onCheckIfEmailIsValid: (email, isValidCallback, notValidCallback) =>
      dispatch(checkIfEmailIsValid(email, isValidCallback, notValidCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Patients));
