import { DateTime } from "luxon";
export default class DateUtil {
  /** BEGIN OF ADDING METHODS OF DATE MANIPULATION USING LUXON */

  static DateTime() {
    return DateTime;
  }

  static addDay(isoDate, numberOfDays) {
    return DateTime.fromISO(isoDate)
      .plus({ day: parseInt(numberOfDays) })
      .toISO();
  }

  static removeDay(isoDate, numberOfDays) {
    return DateTime.fromISO(isoDate)
      .minus({ day: parseInt(numberOfDays) })
      .toISO();
  }

  static addMonth(isoDate, numberOfMonths) {
    return DateTime.fromISO(isoDate)
      .plus({ month: parseInt(numberOfMonths) })
      .toISO();
  }

  static removeMonth(isoDate, numberOfMonths) {
    return DateTime.fromISO(isoDate)
      .minus({ month: parseInt(numberOfMonths) })
      .toISO();
  }

  static addYear(isoDate, numberOfYears) {
    return DateTime.fromISO(isoDate)
      .plus({ year: parseInt(numberOfYears) })
      .toISO();
  }

  static removeYear(isoDate, numberOfYears) {
    return DateTime.fromISO(isoDate)
      .minus({ year: parseInt(numberOfYears) })
      .toISO();
  }

  /** END OF ADDING METHODS OF DATE MANIPULATION USING LUXON */

  static ExcelDateCheck(dateValue) {
    // is the date an ISO date ?
    let myDate = DateTime.fromISO(dateValue).toLocaleString();

    if (myDate === "Invalid DateTime") {
      try {
        // Try to convert an excel formatted date to an ISO date
        let parseFromExcel = new Date(
          Math.round((dateValue - 25569) * 86400 * 1000),
        ).toISOString();
        return DateTime.fromISO(parseFromExcel).toLocaleString();
      } catch {
        return myDate;
      }
    } else {
      return myDate;
    }
  }

  static toDate(date) {
    date = new Date(date);
    let dd = date.getDate();
    let MM = date.getMonth() + 1;
    const yyyy = date.getFullYear();

    if (dd < 10) dd = `0${dd}`;
    if (MM < 10) MM = `0${MM}`;

    return `${dd}/${MM}/${yyyy}`;
  }

  static toyyyyMMdd(date) {
    if (!date) return "";

    var inputDate = new Date(date);

    var year = inputDate.getFullYear();
    var month = inputDate.getMonth() + 1;
    var day = inputDate.getDate();

    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;

    var formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  static toyyyyMMddPatient(date) {
    // if (!date) return "";
    var inputDate = new Date(date);

    var year = inputDate.getFullYear();
    var month = inputDate.getMonth() + 1;
    var day = inputDate.getDate();
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;

    if (year.toString().length === 1) {
      year = "000" + year.toString();
    }
    if (year.toString().length === 2) {
      year = "00" + year.toString();
    }
    if (year.toString().length === 3) {
      year = "0" + year.toString();
    }

    var formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  static toDateWithHour(date) {
    date = new Date(date);
    let dd = date.getDate();
    let MM = date.getMonth() + 1;
    const yyyy = date.getFullYear();

    if (dd < 10) dd = `0${dd}`;
    if (MM < 10) MM = `0${MM}`;

    let hh = date.getHours();
    let mm = date.getMinutes();

    if (mm < 10) mm = `0${mm}`;

    return `${dd}/${MM}/${yyyy} ${hh}:${mm}`;
  }

  static calculateAge(birthDate) {
    if (!birthDate) return "";

    const birthDateObj = new Date(birthDate);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDateObj.getFullYear();
    const currentMonth = currentDate.getMonth();
    const birthMonth = birthDateObj.getMonth();

    if (
      currentMonth < birthMonth ||
      (currentMonth === birthMonth &&
        currentDate.getDate() < birthDateObj.getDate())
    ) {
      age--;
    }

    return age;
  }
}
