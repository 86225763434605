import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Alert, Modal } from "react-bootstrap";
import TextareaCounter from "../../sub/TextareaCounter";
import { splitOrderAdmin } from "../../../actions/orders/orders";
import OrderProductsAdmin from "./OrderProductsAdmin";
import CustomLabel from "../../sub/CustomLabel";
import InputLength from "../../../enums/InputLength";
import MenuButton from "../../sub/bootstrap/MenuButton";

class SplitOrderModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: true,
      comment: "",
    };
  }

  close() {
    this.props.closeConfModal();
  }

  getSplittedProducts() {
    let productsAvailable = [];
    let productsUnavailable = [];

    let inputsUnavailable = document.getElementsByClassName(
      "unavailable-product",
    );
    let inputsAvailable = document.getElementsByClassName("available-product");

    for (let p of this.props.order.products) {
      let product = JSON.parse(JSON.stringify(p));

      for (let inputUnavailable of inputsUnavailable) {
        if (
          inputUnavailable.getAttribute("id") ===
            "unavailable-" + product.id_product &&
          inputUnavailable.value > 0
        ) {
          product.quantity = inputUnavailable.value;
          product.total_ht = product.prix_u_ht_emera * inputUnavailable.value;
          product.total_ttc =
            product.prix_u_ht_emera * inputUnavailable.value +
            product.prix_u_ht_emera * inputUnavailable.value * product.tva;
          productsUnavailable.push(product);
        }
      }

      product = JSON.parse(JSON.stringify(p));

      for (let inputAvailable of inputsAvailable) {
        if (
          inputAvailable.getAttribute("id") ===
            "available-" + product.id_product &&
          inputAvailable.value > 0
        ) {
          product.quantity = inputAvailable.value;
          product.total_ht = product.prix_u_ht_emera * inputAvailable.value;
          product.total_ttc =
            product.prix_u_ht_emera * inputAvailable.value +
            product.prix_u_ht_emera * inputAvailable.value * product.tva;
          productsAvailable.push(product);
        }
      }
    }

    return {
      productsAvailable: productsAvailable,
      productsUnavailable: productsUnavailable,
    };
  }

  checkProductsDisponibility() {
    let data = this.getSplittedProducts();
    this.setState({
      disabled:
        data.productsAvailable.length === 0 ||
        data.productsUnavailable.length === 0,
    });
  }

  split(e, order) {
    if (this.state.disabled) return;

    let data = this.getSplittedProducts();

    data.orderId = order._id;

    if (this.state.comment) data.comment = this.state.comment;

    let successCallback = () => {
      if (this.props.successCallback) this.props.successCallback();
      this.close();
    };

    this.setState({ disabled: true });

    this.props.onSplitOrderAdmin(data, successCallback);
  }

  onChange(key, value) {
    this.setState({ [key]: value });
  }

  render() {
    return (
      <div>
        <Modal
          show={true}
          onHide={() => this.close()}
          backdrop="static"
          keyboard={false}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span>
                {" "}
                <FormattedMessage id="Order" /> {this.props.order.ref}{" "}
              </span>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Alert variant="info">
              <FormattedMessage id="Order.Split.Info" />
            </Alert>

            <OrderProductsAdmin
              order={this.props.order}
              onClickCallback={() => this.checkProductsDisponibility()}
              paginate={true}
            />
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Comment" }) + " :"}
              htmlFor="comment"
              labelClassName="mt-4 mb-2"
            />
            <div>
              <TextareaCounter
                countLimit={InputLength.TEXT_LONG}
                id="comment"
                name="comment"
                value={this.state.message}
                onChange={(e) => this.onChange("comment", e.target.value)}
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <MenuButton variant="secondary" onClick={() => this.close()}>
              <FormattedMessage id="Cancel" />
            </MenuButton>
            <MenuButton
              onClick={(e) => this.split(e, this.props.order)}
              disabled={this.state.disabled}
            >
              <FormattedMessage id="Split" />
            </MenuButton>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSplitOrderAdmin: (data, successCallback) =>
      dispatch(splitOrderAdmin(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(SplitOrderModalAdmin));
