import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import APIUrl from "../../APIUrl";
import DateUtil from "../../util/DateUtil";
import Util from "../../util/Util";
import Footer from "../template/Footer";
import OrderStatus from "../../enums/OrderStatus";
import ProductsParserUtil from "../../util/ProductsParserUtil";
import { nanoid } from "nanoid";
import PriceUtil from "../../util/PriceUtil";
import Roles from "../../enums/Roles";

class OrderTemplatePDF extends React.Component {
  formatTva(tva) {
    return tva >= 1 ? tva : tva * 100;
  }

  render() {
    const { id, order, client, collaborators, user } = this.props;

    let saleRep = null;

    /** SET DISPLAY FOR SALESREP IF FIND */
    for (let collaborator of collaborators) {
      if (
        collaborator.prescribers &&
        collaborator.prescribers.find((e) => {
          return e.prescriber_id === client._id;
        })
      ) {
        saleRep = (
          <div>
            <p>
              <FormattedMessage id="Your.Contact" />:{" "}
              {Util.formatFullName(collaborator.first_name, collaborator.name)}
            </p>
            {collaborator.phone && (
              <p>
                <FormattedMessage id="Phone" />: {collaborator.phone}
              </p>
            )}
            {collaborator.email && (
              <p>
                <FormattedMessage id="Email" />: {collaborator.email}
              </p>
            )}
          </div>
        );
      }
    }

    const orderDate = order.creation_date
      ? DateUtil.toDate(new Date(order.creation_date).getTime())
      : "-";

    let totalHt = 0;
    let totalTtc = 0;
    let totalLppAmount = 0;

    for (const p of order.products) {
      const lppAmount = p.lpp_amount * p.quantity || 0;
      totalHt += PriceUtil.priceHt(p, p.quantity, 2);
      totalTtc += PriceUtil.priceTtc(p, p.quantity, 2);
      totalLppAmount += lppAmount;
    }

    const totalTva = totalTtc - totalHt;
    const total = totalTtc - totalLppAmount;

    let imgError = false;

    const shippingDelay =
      order.shipping_delay && order.creation_date
        ? DateUtil.toDate(
            new Date(order.creation_date).getTime() +
              order.shipping_delay * 24 * 3600 * 1000,
          )
        : 0;

    return (
      <div id={id}>
        <div id="header">
          <table>
            <tbody>
              <tr>
                <td id="pharma_logo_td">
                  {this.props.generalSettings.logo && (
                    <img
                      id="pharma_logo"
                      src={`${APIUrl.getGeneralSettingsLogo}${
                        this.props.user.company_id
                      }/${this.props.generalSettings._id}/${Math.random()}`}
                      alt="logo client"
                      onError={(e) => {
                        if (imgError) return;
                        e.target.onerror = null;
                        imgError = true;
                        e.target.src = "/images/placeholder_120x120.png";
                      }}
                    />
                  )}
                  <div className="titulaire">
                    <p>
                      <FormattedMessage id="Date" /> : {orderDate}
                      <br />
                      {this.props.order.status === OrderStatus.NEW
                        ? "(" +
                          this.props.intl.formatMessage({
                            id: "Waiting.Delivery",
                          }) +
                          ")"
                        : ""}
                      {this.props.order.status === OrderStatus.BLOCKED
                        ? "(" +
                          this.props.intl.formatMessage({
                            id: "Delivery.In.Progress",
                          }) +
                          ")"
                        : ""}
                      <br />
                      {shippingDelay !== 0 && (
                        <>
                          <FormattedMessage id="Order.Estimated.Delivery.Date" />{" "}
                          : {shippingDelay}
                        </>
                      )}
                    </p>
                  </div>
                </td>
                <td id="app_logo_td">
                  <img
                    id="app_logo"
                    src={APIUrl.rootUrl + "/img/pharmamad_logo.svg"}
                    alt=""
                  />
                  <div id="order_infos">
                    <p>
                      <FormattedMessage id="Client" /> :{" "}
                      {Util.formatFullName(client.first_name, client.name)}
                      <br />
                      {client.address}
                      <br />
                      {client.postal_code || ""} {client.city || ""}
                    </p>
                    {saleRep && saleRep}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h1 id="order_title">
          <FormattedMessage
            id="Order.Info"
            values={{
              orderNum: this.props.order.ref,
              orderDate: orderDate,
            }}
          />
        </h1>

        <div id="order">
          <table className="table">
            <thead>
              <tr>
                <td className="align-middle">
                  <FormattedMessage id="Ref.Short" />
                </td>
                <td style={{ width: "20%" }}>
                  <FormattedMessage id="Product" />
                </td>
                <td className="align-middle">
                  <FormattedMessage id="Qty" />
                </td>
                {user.role !== Roles.CLIENT && (
                  <>
                    <td className="align-middle">
                      <FormattedMessage id="Lpp.Code" />
                    </td>
                    <td className="align-middle">
                      <FormattedMessage id="Lpp.Amount" />
                    </td>
                  </>
                )}
                <td className="align-middle">
                  <FormattedMessage id="Unit.Price.Excl.Tax" />
                </td>
                <td style={{ width: "8%" }}>
                  <FormattedMessage id="Unit.Tax" />
                </td>
                <td className="align-middle">
                  <FormattedMessage id="Unit.Price.Incl.Tax" />
                </td>
                <td className="align-middle">
                  <FormattedMessage id="Total.Excl.Tax" />
                </td>
                <td className="align-middle">
                  <FormattedMessage id="Total.Incl.Tax" />
                </td>
              </tr>
            </thead>
            <tbody>
              {order.products.map((p) => (
                <tr key={nanoid()}>
                  <td className="align-middle">{p.ref}</td>
                  <td style={{ width: "20%" }}>
                    {ProductsParserUtil.swapDesignationDelimiter(p.designation)}
                  </td>
                  <td className="align-middle">{p.quantity}</td>
                  {user.role !== Roles.CLIENT && (
                    <>
                      <td className="align-middle">{p.lpp_code || "-"}</td>
                      <td className="align-middle">
                        {p.lpp_amount
                          ? PriceUtil.formatEuro(p.lpp_amount * p.quantity)
                          : "-"}
                      </td>
                    </>
                  )}
                  <td className="align-middle">
                    {PriceUtil.formatEuro(PriceUtil.priceHt(p, 1, 2))}
                  </td>
                  <td style={{ width: "8%" }}>{this.formatTva(p.tva)} %</td>
                  <td className="align-middle">
                    {PriceUtil.formatEuro(PriceUtil.priceTtc(p, 1, 2))}
                  </td>
                  <td className="align-middle">
                    {PriceUtil.formatEuro(PriceUtil.priceHt(p, p.quantity, 2))}
                  </td>
                  <td className="align-middle">
                    {PriceUtil.formatEuro(PriceUtil.priceTtc(p, p.quantity, 2))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <table
            className="table table-total"
            style={{ marginTop: "30px", width: "30%", float: "right" }}
          >
            <tbody>
              <tr>
                <td className="align-middle">
                  <FormattedMessage id="Total.Excl.Tax" />
                </td>
                <td className="align-middle">
                  {PriceUtil.formatEuro(totalHt)}
                </td>
              </tr>
              <tr>
                <td className="align-middle">
                  <FormattedMessage id="Unit.Tax" />
                </td>
                <td className="align-middle">
                  {PriceUtil.formatEuro(totalTva)}
                </td>
              </tr>
              <tr>
                <td className="align-middle">
                  <FormattedMessage id="Total.Incl.Tax" />
                </td>
                <td className="align-middle">
                  {PriceUtil.formatEuro(totalTtc)}
                </td>
              </tr>
              {user.role !== Roles.CLIENT && (
                <>
                  <tr>
                    <td className="align-middle">
                      <FormattedMessage id="Total" />{" "}
                      <FormattedMessage id="Lpp.Amount" />
                    </td>
                    <td className="align-middle">
                      {PriceUtil.formatEuro(totalLppAmount)}
                    </td>
                  </tr>
                  <tr className="total">
                    <td className="align-middle">
                      <FormattedMessage id="Remains" />
                    </td>
                    <td className="align-middle">
                      {PriceUtil.formatEuro(total)}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>

          {order.comment && order.comment !== "" && (
            <div>
              <p>
                <strong>
                  <FormattedMessage id="Comment" /> :{" "}
                </strong>
                {order.comment}
              </p>
            </div>
          )}
        </div>

        {id !== "preview" && (
          <Footer generalSettings={this.props.generalSettings} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    generalSettings: state.generalSettings,
    company: state.company,
    collaborators: state.collaborators,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(OrderTemplatePDF));
